.cardPreview {
    display: flex;
    margin: 10px;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-family: Poppins;
    align-items: center;
    background-color: var(--color-semiwhite);
   border-left: 10px solid var(--color-blue);
}

.cardPreview:hover {
    border-left: 20px solid var(--color-pink);
}


.cardButton {
    background-color: var(--color-white);
    border-radius: 5px;
    border:2px solid var(--color-darkblue);
    padding-top: 5px;
    font-family: Poppins;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
}

.singleColumn {
    text-align: center;
   
    font-size: small;
    margin: 5px;
}

.profileColumn {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-size: small;
    min-width: 20%;
    margin: 5px;
}

.evaluateButton {
    margin: 5px;

    background-color: var(--color-darkblue);
    border-radius: 5px;
    border:2px solid var(--color-white);
    padding-top: 5px;
    
    color: var(--color-white);
    font-family: Poppins;
    padding-bottom: 5px;
    padding-left: 50px;
    padding-right: 50px;
}

.rating {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: small;
}

.evaluateModalForm {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.evaluateModalLabel {
    color:var(--color-darkblue);
    font-size: medium;
    font-weight: 500;
    padding: 20px;
    width: 50%;
}

.commentDescription {
    width: 200px;
    height: 100px;
    background-color: var(--color-semiwhite);
    border:none

}
.evaluateModalDiv {
    
    display: flex;
    justify-content: center;
    margin: 20px;
}
  



@media only screen and (max-width: 1000px) {
 .cardPreview{
     display: flex;
     flex-direction: column;
     justify-content: center;
  }

  .profileColumn {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: small;
    min-width: 25%;
    margin: 5px;
}




}