.eventPage{
    /* background: url('../../images/white\ bg.png');
    background-size: cover;
    background-repeat: no-repeat; */
    background: rgb(177, 35, 19);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    border: 100px bold turquoise;
}
.eventBackGround{
    width: 500px;
    height: 150px;
    display: grid;
    position: relative;
    place-items: center;
}
.eventBackGround::before{
    content: "";
    /* background: url('../../images/wUntitled-1.png') no-repeat center center;
    background-size: cover; */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

}
.eventBackGround h1{
    color: white;
    font-size: 36px;
}
.eventBackGround h2{
    color: white;
    font-size: 32px;
    font-weight: normal;
}
.categoryName{
    width: 400px;
    height: 150px;
    position: relative;
    display: grid;
    place-items: center;
}
.categoryName::before{
    content: "";
    /* background: transparent url('../../images/wUntitled-1.png') no-repeat center center;
    background-size: cover; */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.categoryName h2{
    color: white;
    font-size: 32px;
    font-weight: normal;
}

.batchCreation{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    position: relative;
    
}

.createBatch{
    padding: 20px 0;    
    background-color: white;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40vh;
}

.createBatch>h2{
    font-size: 32px;
    text-transform: uppercase;
    color: #ec78a6;
}
.batchInput{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 60%;
    position: relative;
    z-index: 2;
}
.batchInput label{
    font-size: 20px;
    font-weight: 400;
}
.batchInput input{
    width: 35px;
    outline: none;
    height: 35px;
    font-size: 18px;
    font-weight: 700;
    background-color: lightgray;
    border: none;
    
}
.batchInput input::-webkit-outer-spin-button,
.batchInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
.batchInput input[type=number] {
  -moz-appearance: textfield;
}

.createButton{
   background-color: #ec78a6;
   border: none;
   width: 150px;
   height: 30px;
   color: white;
   font-size: 16px;
   cursor: pointer;
}

@media screen and (max-width:450px) {
    .eventBackGround{
        width: 200px;
        height: 50px;
        display: grid;
        position: relative;
        place-items: center;
    }
    .eventBackGround h1{
        color: white;
        font-size: 14px;
    }
    .eventBackGround h2{
        color: white;
        font-size: 12px;
        font-weight: normal;
    }
    .categoryName{
    
        width: 200px;
        height: 50px;
        position: relative;
        display: grid;
        place-items: center;
    }
    .categoryName h2{
        color: white;
        font-size: 16px;
        font-weight: normal;
    }
    .createBatch{
        padding: 20px 0;    
        background-color: white;
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 40vh;
    }
    .createBatch>h2{
        font-size: 14px;
        text-transform: uppercase;
        color: #ec78a6;
    }
    .batchInput label{
        font-size: 10px;
        font-weight: 400;
    }
    
}