@import '../../../../css/global.css';

.rating {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: small;
}

.evaluateModalForm {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.evaluateModalLabel {
    color:var(--color-darkblue);
    font-size: medium;
    font-weight: 500;
    padding: 20px;
    width: 50%;
}

.commentDescription {
    width: 200px;
    height: 100px;
    background-color: var(--color-semiwhite);
    border:none

}
.evaluateModalDiv {
    
    display: flex;
    justify-content: center;
    margin: 20px;
}
  

