.button{
    /* border: 1px solid black; */
    width: 220px;
    height: 220px;
    background-color: #f1b6ce;
    cursor: pointer;
    position: relative;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
}
.button img{
    z-index: 5;
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.button h1{
    color:#f272a6 ;
}
.arrow{
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow span:first-child{
    width: 5px;
    height: 5px;
    background-color: #f272a6;
    margin-left: 10px;
}
.arrow span{
    width: 10px;
    height: 5px;
    background-color: #f272a6;
    margin-left: 10px;
}
.arrowIcon{
    width: 60px;
    height: 50px;
    color: #f272a6;
}


.buttonEven{
    /* border: 1px solid black; */
    width: 220px;
    height: 220px;
    background-color: rgb(191, 231, 245);
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
}
.buttonEven img{
    z-index: 5;
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.buttonEven h1{
    color:#08b0c2 ;
}
.arrowEven{
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrowEven span:first-child{
    width: 5px;
    height: 5px;
    background-color: #08b0c2;
    margin-left: 10px;
}
.arrowEven span{
    width: 10px;
    height: 5px;
    background-color: #08b0c2;
    margin-left: 10px;
}
.arrowIconEven{
    width: 60px;
    height: 50px;
    color: #08b0c2;
}

@media screen and(max-width:850px) {
    .button{
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
    }
    .buttonEven{
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
    }
}


@media screen and (max-width:1200px) {
    .button{
        width: 150px;
        height: 150px;
    }
    .button img{
        width: 60px;
        height: 60px;
    }
    .buttonEven{
        width: 150px;
        height: 150px;
    }
    .buttonEven img{
        width: 60px;
        height: 60px;
    } 
}
