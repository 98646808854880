.participantTable{
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
}
.participantTable th{
    border-right: 5px solid lightgray;
    color: #ec78a6;
    text-transform: capitalize;
}
.participantTable th:last-child{
    border-right: none;
}

.participantTable table{
    
    border-collapse: collapse;
}
.participantTable td{
    border-top: 5px solid lightgray;
    border-right: 5px solid lightgray;
    color: #ec78a6;
    text-transform: capitalize;

}
.participantTable td:last-child{
    border-right: none;
}

.participantTable th,td{
    padding: 5px;
}

.participantTable tr select{
    background-color: rgb(191, 231, 245);
    border: none;
    padding: 5px;
    font-size: 14px;
    color: #ec78a6;
    font-weight: 600;
    outline: none;
}

.participantTable tr button{
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ec78a6;
    border: none;
    padding: 5px;
    background-color: rgb(191, 231, 245);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}
