@import url("../../css/global.css");

* {
    margin: 0px;
}

.form-control {
    width: 100%;
}

.modalBackdrop {
    position: fixed;
    top: 70px;
    left: 0px;
    display: flex;
    background-color: #f5f5f5;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow-y: auto;
}

.modal {
    --modal-width: 1080px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 5px 5px lightgrey;
    text-align: center;
    width: 95%;
    height: 95%;
    max-width: var(--modal-width);
    max-height: 580px;
    overflow-y: auto;
}

.modalLeftSideBlue, .modalLeftSideWhite {
    width: calc(calc(var(--modal-width)*11)/20);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalLeftSideBlue {
    background-color: var(--color-darkblue);
    color: white;
}

.modalLeftSideWhite {
    background-color: white;
    color: var(--color-darkblue);
}

.leftSideContentContainer {
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leftSideTextContainer {
    padding: 0px 80px;
}
.leftSideTextContainer>p {
    font-size: 0.5rem;
}

.leftSideImgContainer {
    max-width: 400px;
    max-height: 350px;
}
.leftSideImgContainer img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.modalRightSideBlue, .modalRightSideWhite {
    width: calc(calc(var(--modal-width)*9)/20);
    height: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.modalRightSideBlue {
    background-color: var(--color-darkblue);
    color: white;
}

.modalRightSideWhite {
    background-color: white;
    color: var(--color-darkblue);
}

.modalRightSideContentContainer {
    width: 350px;
    height: 100%;
    max-width: 100%;
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.backBtnContainer {
    display: flex;
    align-items: center;
    width: 100%;
    
}

.backBtn {
    font-size: small;
    color: var(--color-darkblue);
}

.backBtn:hover {
    cursor: pointer;
    
}

.modalFormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 50px);
    width: 80%;
}
.modalFormGetPhoneContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 50px);
    width: 80%;
}

.phoneNumberContainer {
    margin-left: -10px;
}

.modalForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.mildText {
    color: darkgrey;
    font-size: 10px;
    margin-top: -2vh;
}
.warnText {
   
    font-size: 10px;
    color: red;
}


.linkBtn {
    text-decoration: underline;
}
.linkBtn:hover {
    cursor: pointer;
}

/* .customInput {
    border: 1px solid black;
    margin-top: 10px;
    border-radius: 10px;
    background-color: white;
    color: black;
}
.customInput input {
    height: 35px;
    padding-left: 10px;
    border-radius: 0px 10px 10px 0px;
    border: none;
    color: black;
    font-size: 17px;
}
.customInput span {
    height: 35px;
    padding-left: 10px;
    border-radius: 10px 0px 0px 10px;
} */

.modalForm button {
    background-color: var(--color-blue);
    color: black;
    border-radius: 10px;
}

.modalForm button:hover {
    background-color: var(--color-blue);
    transform: scale(1.05);
}

@media screen and (max-width: 900px) {
    .modalBackdrop {
        height: calc(100vh - 140px);
    }
}

@media screen and (max-width: 850px) {
    .modalRightSideBlue {
        padding: 0px;
    }
}
@media screen and (max-width: 820px) {
    .modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 95%;
        height: 100%;
        background-color: white;
        max-height: none;
        max-width: none;
        overflow-x: auto;
        overflow-y: auto;
    }

    .modalLeftSideBlue, .modalLeftSideWhite {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .leftSideTextContainer {
        padding: 0px 20px;
    }
    .leftSideTextContainer>p{
        font-size: 0.5rem;
    }
    .leftSideImgContainer {
        max-width: none;
        max-height: none;
        height: calc(100% - 60px);
    }

    .modalRightSideBlue, .modalRightSideWhite {
        width: 100%;
        height: 50%;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modalRightSideContentContainer {
        justify-content: flex-start;
        padding: 0px;
    }

   
   
}
@media screen and (max-width: 360px) {
    .modalRightSideWhite, .modalRightSideBlue {
        padding: 0px;
    }
}
@media screen and (max-width: 820px) {
    .modalBackdrop {
        padding: 0px;
    }
    .modal {
        width: 100%;
        margin: 0px;
        height: 100%;
    }
    .modalLeftSideBlue, .modalLeftSideWhite {
        background-color: #f5f5f5;
        height: 50%;
        color: black;
    }
    .modalRightSideBlue, .modalRightSideWhite {
        color: black;
        background-color: #f5f5f5;
        height: 50%;
    }

    .modalFormContainer {
        display: inline;
        
    }

 
    
    .modalForm {
        position: relative;
        top:0px;
        gap:5px;
        justify-content: space-around;
        
    }
}