/* .eventComponent{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background: ;
} */

.eventComponentName{
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #dddddd;
    box-shadow: 1px 5px 10px 1px rgb(99, 99, 99);
    margin: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.futureComponetName{
    display: flex;
    flex-direction: row;
}
.eventname{
        font-size: 20px;
        text-align: center;
        color: #242626;   
}
.imagecontainer{
    background: rgb(248, 248, 244);
    margin: 10px;
    height: 100px;
    width: 100%;
}
.evenButton{
    background-color: 3px solid lightgray;
    color: #08b0c2;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;
}
.oddButton{
    background-color: 3px solid lightgray;
    color: #f272a6;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;
}
.evenButton:hover{
    transform: scale(1.02);
}
.oddButton:hover{
    transform: scale(1.02);
}

.eventTypeButtonsoverlay{
    position: absolute;
    display: flex;
    text-align: center;
    justify-content: center;
    top: 0;
    left: 0;
    margin: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.419);
    width: 100%;
    height: 100%;
    z-index: 10;
}
.even{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.doubleclick{
      position: absolute;
      color: white;
          margin-top: 15%;
}
.imgcontainer{
    width: 100%;
    height: 100px;
    margin-top: 10px;
}
.imgcontainer img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    box-shadow: 0 10px 10px rgba(85, 90, 90, 0.289) inset;
}
.eventComponentName:hover .imgcontainer img {
    background-color: wheat;
}

.closebutton{
    height: 50px;
    width: 50px;
    font-weight: 900;
    position: absolute;
    margin-left: 1%;
    margin-top: 10%;
    text-align: center;
    border-radius: 50%;
    padding-top: 15px;
    background: rgb(238, 238, 238);
}
.eventTypeButtons{
    display: flex;
    align-items: center;
    flex: 0.8;
    justify-content: space-around;
}
.futureComponent{
    border: 1px solid black;
    display: flex;
}


@media screen and (max-width:1200px) {
    .eventComponentName{
        width: 200px;
        height: 200px;
    }
    
    .even{
        font: 24px;
    }
    .odd{
        font-size: 24px;
    }
}

@media screen and (max-width:850px) {
    .eventComponent{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%
    }
    .eventTypeButtons{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }
    .eventComponent::after{
        content: "-";
        background-color: black;
        height: 3px;
        width: 100%;
    }
}