.loader{
    display: flex;
    flex-direction: column;
    flex: 1;
    /* border: 1px solid black; */
    align-items: center;
    justify-content: center;
}
.spinner{
    width: 200px;
    height: 200px;
}