.CourseForm {
  margin: 50px 30px;
}

.header {
  margin-bottom: 20px;
}
.formnav {
  list-style-type: none;
  margin-left: 0 !important;
  margin-bottom: 20px;
}
.formnav li {
  display: inline;
  margin-right: 50px;
}

.textfield {
  width: 100%;
  border-radius: 5px;
}
.unactiveli {
  padding: 2px;
}
.active {
  border-bottom: 4px solid #64d8e0;
  padding: 2px;
}

.submitbutton {
  background-color: #64d8e0;
  border: none;
  padding: 10px 20px;
  font-size: 22px;
  border-radius: 8px;
  color: black;
  text-transform: uppercase;
}
.SetupForm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 50px;
}
.orgSetup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.priceSetup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
}

.leftSetup {
  flex-basis: 800px;
}

.rightSetup {
  flex-basis: 350px;
}

.buttondiv {
  margin: 10px 50px;
}

.textfieldcourseDetails {
  height: 40vh;
  width: 500px;
}

.courseHighlightsContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
}

.courseHighlightsContent div {
  flex-basis: 45%;
}

.paracourseHL {
  margin: 10px 0px;
  font-size: 17px;
  line-height: 26px;
}

.paralinescourseHL {
  font-size: 17px;
  line-height: 26px;
}

input[type="file"]::file-selector-button {
  border: 2px solid #f085b0;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #f085b0;
  transition: 1s;
  font-size: 22px;
}

input[type="file"]::file-selector-button:hover {
  border: 2px solid #cc5577;
}
.fileInput {
  border: 1px solid black;
  padding: 5px 10px;
  width: 90%;
  margin: 5px 0px;
  border-radius: 5px;
}
.addInstructor{
  margin-bottom: 50px;
}
.addInstructorUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.addInstructorUpper div {
  flex-basis: auto;
}

progress {
  display: block;
  width: 90%;
}


.addTopic,.faq{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.addTopicForm, .addedTopic,.faqForm,.addedFAQs{
  flex-basis: 47%;
}

.addedTopicList, .listOfFAQs{
  padding: 10px 0;
  margin-top: 10px;
  background-color: white;
  min-height: 34vh;
  max-height: 35vh;
  overflow-y: scroll;
  border: 1px solid gainsboro;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.highLightsList{
  padding: 10px 0;
  margin: 10px 0;
  background-color: white;
  min-height: 14vh;
  max-height: 16vh;
  overflow-y: scroll;
  border: 1px solid gainsboro;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}




@media only screen and (max-width: 600px) {
  .unactiveli {
  display: none !important;
  }

  .active{
    text-align: center;
  }

  .addInstructorUpper div{
    flex-grow: 1;
  }
  .courseHighlightsContent div {
    flex-grow: 1;
    flex-basis: 100%
  }
  .buttondiv {
    margin-top: 10px;
    padding-bottom: 50px;
    margin-right: 0;
    margin-left: 0;
  }
  .submitbutton{
    font-size: 20px;
  }

  .leftSetup {
    flex-grow: 1;
    flex-basis: 100%
  }
  
  .rightSetup {
    flex-grow: 1;
    flex-basis: 100%
  }
  .SetupForm{
    gap:10px;
  }

  .addTopicForm, .addedTopic,.faqForm,.addedFAQs{
    flex-basis: 100%;
    flex-grow: 1;

  }

  /* .addInstructorUpper {
  box-sizing: border-box;

    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  } */



}