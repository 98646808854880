/* .moduleCardWrapper {
    display: inline-block;
} */

.moduleCard {
    display: inline-block;
    position: relative;
    margin: 0px 10px 10px 0px;
    /* aspect ratio - 3:4.5 () */
    padding: 15px;
    color: white;
    background-color: #2b324a;
    border-radius: 25px;
    text-decoration: none;
    text-align: center;
    /* aspect ratio - 3:4.5 () */
    width: 300px;
    height: 359px;
}

.moduleCardImage {
    margin-top: 10px;
    object-fit: contain;
    background-color: black;
    /* aspect ratio - 4:3 */
    width: 270px;
    height: 180px;
}

.moduleCardDescription {
    display: inline-block;
    text-align: center;
    width: 100%;
    overflow-y: auto;
    margin-top: 20px;
    height: calc(100% - 310px);
}

.moduleCard:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.2s ease-in-out;
}

.moduleCard:hover .moduleCardImage {
    opacity: 0.6;
}

.moduleName {
    text-align: center;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    overflow-y: hidden;
}

.tutorPreviewContainer {
    position: absolute;
    left: calc(50% - 65px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: -90px;
    text-align: center;
    z-index: 1;
    color: black;
}

.tutorPreviewContainer:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.1s ease-in-out;
}

.tutorImgContainer {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: black;
}

.tutorImgContainer:hover .tutorImg {
    opacity: 0.7;
}

.tutorName {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    overflow-y: hidden;
    color: white;
}

.tutorImg {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.tutorRating {
    margin-top: 10px;
}

@media screen and (max-width:720px) {
    .moduleCard {
        width: 100%;
        height: 50vw;
        margin-bottom: 90px;
    }
    .moduleName {
        margin-bottom: 15px;
    }
    .moduleCardImage {
        width: calc(50% - 10px);
        height: calc(50vw - 125px);
        margin: 0px;
        float: left;
    }
    .moduleCardDescription {
        float: right;
        width: calc(50% - 10px);
        height: calc(100% - 120px);
    }
    .tutorPreviewContainer {
        bottom: -70px;
    }
    .tutorImgContainer {
        height: 75px;
        width: 75px;
    }
}

@media screen and (max-width:500px) {
    .moduleCard {
        margin-bottom: 80px;
    }
    .moduleCardDescription {
        margin-top: 5px;
        float: right;
        width: calc(50% - 10px);
        height: calc(100% - 100px);
    }
    .tutorPreviewContainer {
        bottom: -60px;
    }
    .tutorImgContainer {
        height: 50px;
        width: 50px;
    }
}