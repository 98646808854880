.MyEventsPage {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 10px 60px;
}
.createEvent{
    padding: 20px 40px;
}

.createEventButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.uncompletedEvents{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.uncompletedEventsList{
    background-color: #2b324a;
    padding: 30px 10px;
    color: white;
    font-size: 22px;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    width: fit-content;
    border-radius: 10px;

}

.unActiveEvents, .activeEvents{
    margin: 30px 0;

}

@media screen and (max-width: 720px) {
    .EventPage{
        padding: 20px 0;
    }
    .createEvent{
        padding: 20px 0;
    }

    .uncompletedEvents{
        justify-content: center;
        
    }
    .uncompletedEventsList{
        min-width: 90vw;
    }
}