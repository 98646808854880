.events{
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;

}
.eventTitle{
    color: #0c4e89;
    font-size: 56px;
}
.batchbutton{
    height: 100px;
    width: 100px;
    margin: 10px;
}
.eventstype{
    font-size: 20px;
    font-weight: 900;
    color: rgb(1, 1, 68);
}
.futureEventsComingSoon{
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
    flex-wrap: wrap;
}
.eventscompleted{
    display: flex;
    overflow-x: scroll;
}
.eventscompleted::-webkit-scrollbar {
    background: rgba(136, 230, 242, 0.563);
    height: 0.5em;
}
.eventscompleted::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  height: 1px;
  border-radius: 10px;
  outline: 1px solid slategrey;
}





@media screen and(max-width:850px) {
    .eventTitle{
        font-size: 32px;
    }
}
