.TutorLanding {
  background-color: #2b324a;
  color: white;
  padding: 0;
  display: flex;

  align-items: center;
  flex-direction: column;
  margin: 0;
  box-sizing: border-box;
  max-width: 100vw;
}

.firstPageDiv {
  position: relative;
  display: flex;
  max-width: 1500px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  background-color: #2b324a;
  min-height: 100vh;
}

.element1 {
  position: absolute;
  top: 100px;
  width: 120px;
  left: 605px;
}
.element12 {
  position: absolute;
  bottom: 90px;
  width: 120px;
  z-index:1;
  right: 0px;
}
.plus1 {
  position: absolute;
  top: 90px;
  width: 60px;
  left: 85px;
}

.plus2 {
  position: absolute;
  bottom: 200px;
  width: 60px;
  right: 250px;
}


.circle1 {
  position: absolute;
  top: 20px;
  width: 30px;
  right: 305px;
  z-index: 0;
}
.circle2 {
  position: absolute;
  top: 300px;
  z-index: 01;
  width: 30px;
  left: 300px;
}
.lowerImg {
  position: absolute;
  bottom: 12vh;
  width: 60px;
  right: 50px;
  display: flex;
  justify-content: start;
}
  
 

.dummyTeacher img {
  position:relative;
  margin-right: 5vh;
  margin-bottom: -11vh;
  width: 500px;
}
.element4 {
  height: 50px;
  margin-left: -20px;
}
.element5 {
  
  height: 50px;
}

.rightFirst {

  padding: 20px 0px;
  max-width: 350px;
 
}

.firstuppercaseLines {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 40vh;
  font-size: medium;
  text-transform: uppercase;
}
.firstuppercaseLines p {
  text-transform: uppercase;
  line-height: 20px;
}




.uppercaseLines {
  margin-top: 10px;
  font-size: medium;
  text-transform: uppercase;
  font-weight: 800;
  
}
.uppercaseLines p {
  text-transform: uppercase;
  line-height: 15px;
}

.bluish {
  color: #50d0db;
}
.pinkfont {
  color: #f085b0;
}

.catchyheading {
  border-bottom: 1px white solid;
}

.pinkButton {
  padding: 10px 45px;
  background-color: #f085b0;
  color: white;
  font-weight: 700;
  font-size: large;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
}

.secondPageDiv {
  min-height: 100vh;
  background-color: #2b324a;
  display: flex;
 
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  
}





.leftSecond img,
.rightSecond img {
  height: 200px;
}

.bottomDiv {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bottomDiv button {
  margin-top: 20px;
  margin-bottom: 10px;
}

.headerpagethree {
  text-align: center;
  /* font-size: 50px; */
}

.thirdPagediv {
  margin: auto 0;
  /* height: 100%; */
  background-color: #2b324a;
  padding-bottom: 50px;
}

.cardsDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card {
  flex-basis: 30%;
  box-sizing: border-box;
  text-align: center;
  padding: 30px 20px;
}
.inDepthImg {
  
  height: 100px;
  
}

.thirdDescription {
  
  font-size: large;

}
.signupbuttondiv {
  padding: 40px 0px;
  display: flex;
  margin-top: -30px;
  align-items: center;
  
  justify-content: center;
}
.rightFirstpara {

  text-transform: capitalize !important;}


@media only screen and (min-width: 1000px){


.displayrow {
  display: flex;
  flex-direction: row;
  justify-content: space-;
  align-items: center;
  flex-wrap: wrap;
}

.leftSecond,
  .rightSecond {
    position: relative;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
  
    
  
  }





}


@media only screen and (max-width: 1000px) {
  .firstPageDiv {
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
   
   align-items: center;
    gap: 0;
  }
  .dummyTeacher{
    align-items: center;
  }
  .dummyTeacher img {
    margin-right: 0;
    height: 30vh;
    width: 30vh;

    margin-top: -15vh;
    margin-bottom: 0vh;
    content: url(../../assets/teacherShape.png);
  }
  .circle1 {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 105px;
  }
  .circle2 {
    position: absolute;
    top: 300px;
    
    width: 20px;
    right: 80px;
  }
  .plus1 {
    position: absolute;
    top: 300px;
    width: 60px;
    left: 35px;
  }

  .lowerImg {

    display: none;
  }

  .plus2 {
    display: none;
  }
  .element1 {
    position: absolute;
    top: 22px;
    width: 70px;
    left: 15px;
  }

  .element12 {
   display: none;
  }
  .headerPageOne {
    font-size: 15px;
    line-height: 30px;
  }
  .rightFirst {
    margin-top: -20vh;
    padding: 0;
    text-align: center;
   
  }
  .rightFirstpara {
    margin-top: 5px;
    text-transform: capitalize;
    font-size: medium;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 1px;
  }
  .pinkButton {
    margin-top: 20px;
  }

  .displayrow {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
  }

  .leftSecond,
  .rightSecond {
    position: relative;
    text-align: center;
  
    
  
  }

  .rightSecond {
    margin-top: 10px;
  }


  .cardsDetails {

    flex-direction: column;

  }

  .card {
    flex-basis: 100%;
    /* flex-grow: 1;
     */
  }





  




  .leftSecond img,
  .rightSecond img {
  
    height: 15vh;
  }

 

  .inDepthImg {
    
    height: 100px;
  }

  .thirdDescription{
    
    font-size: large;
  }



  .rightSecond img {
    padding-top: 20px;
  }

  .headerpagethree {
    text-align: center;
    font-size: large;
  }

  .secondPageDiv {
    margin-top: -200px;
    height: 100vh;
    justify-content: space-evenly;
  }
  .secondPageDiv .pinkButton {
    padding: 10px;
  }

  .bottomDiv {
    font-size: x-large;
  }

  .boldText {
    font-size: large;
  }
}
