@import '../../css/global.css';

.cards{
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.cardHolder {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--color-semiwhite);
}

.nameText {
    text-align: center;
    background-color: var(--color-semiwhite);
    color: var(--color-darkblue);
    padding-top: 20px;
    font-family: Poppins;
    font-weight: 900;
    font-size:xx-large;
}