@import '../../css/global.css';



/* Style the tab */
.tab {
    display: flex;
    justify-content:space-between;
    background-color: var(--color-white);

}






.activebutton {
    outline: none;
    color: var(--color-darkblue);
    background: none;
    border:none;
    padding: 10px;
    font-weight: 500;
    border-bottom: 5px solid var(--color-blue);
    font-size: medium;
    font-family: Poppins, sans-serif;
    
    cursor: pointer;
}


.normalbutton {
    outline: none;
    color: var(--color-darkblue);
    background: none;
    border:none;
    padding: 10px;
    font-size: medium;
    font-family: Poppins, sans-serif;
    
    cursor: pointer;
}

.normalbutton:hover {
    color: var(--color-blue);
}







