.login{
    /* background: url('../../images/blue\ bg.png');
    background-size: cover;
    background-repeat: no-repeat; */
    background: #121c38;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
}
.loginLeft{
    display: flex;
    align-items: center;
}
.loginLeft img{
    object-fit: contain;
    width: 250px;
}
.loginLeftDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    color: white;
}
.loginLeftDetails h1{
    font-size: 40px;
    padding-bottom: 3px;
    border-bottom: 2px solid white;
}
.loginLeftDetails h3{
    font-size: 29.9px;
}
.loginContainer{
    position: relative;
    color: #ec78a6;
    background-color: #fff;
    /* padding: 20px 40px; */
    display: flex;
    border-radius: 30px;
    flex-direction: column;
    /* gap: 50px;  */
    height: 430px;
    width: 450px;
}
.userImage{
    width: 100%;
    position: relative;
    height: 90px;
    background-color: #6ae4ed;
    box-shadow: 0px 10px 30px  #121c3869;
    border-radius: 30px;
    /* background: url('../../images/wUntitled-1.png') no-repeat center center/cover; */
}

.userImage img{
    width: 100px;
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.userImage svg{
    color: rgb(243, 241, 241);
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.loginContainer form{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    padding: 20px 40px;
}
.inputDetails{
    background-color: lightgray;
    border-radius: 10px;
    box-shadow: 5px 5px 7px 2px rgb(167, 166, 166);
    align-self: center;
}
.inputDetails input{
    background-color: transparent;
    border: none;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    outline: none;
    font-size: 16px;
    font-weight: 600;
}
.loginButton{
    align-self: center;
    width: 120px;
    height: 30px;
    background-color: #ec78a6;
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

}

@media screen and (max-width:750px) {
    .login{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .loginLeft{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .loginLeft img{
        width:150px
    }
    .loginLeftDetails h1{
        font-size: 18px;
    }
    .loginLeftDetails h3{
        font-size: 12px;
    }
    .loginContainer{
        width: 200px;
        height: 200px;
    }
    .userImage{
        height: 45px;
        position: relative;
        width: 100%;
        background: url('../../images/wUntitled-1.png') no-repeat center center/cover;
    }
    .userImage img{
        width: 30px;
        position: absolute;
        bottom: -50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .userImage svg{
        width: 30px;
        height: 30px;
    }
    .loginContainer form{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        padding: 5px 10px;
    }
    .loginContainer form h1{
        font-size: 14px;
    }
    .inputDetails{
        background-color: lightgray;
        border-radius: 10px;
        box-shadow: 5px 5px 7px 2px rgb(167, 166, 166);
        align-self: center;
    }
    .inputDetails input{
        background-color: transparent;
        border: none;
        width: 100%;
        height: 10px;
        padding-left: 10px;
        outline: none;
        font-size: 8px;
        font-weight: 600;
    }
    .loginButton{
        align-self: center;
        width: 100px;
        height: 20px;
        background-color: #ec78a6;
        border: none;
        color: white;
        font-size: 8px;
        font-weight: 600;
        cursor: pointer;
    
    }
}

@media screen and (max-width:1200px) {
    .login{
        display: flex;
        flex-direction: column;
    }
}

