@import '../../../css/global.css';

.cardview{
   display: flex;
   flex-direction: column; 
   justify-content: center;
   box-shadow: 2px 2px #0000003F;
   width: 20vw;
  padding: 10px;
  margin: 10px;
   min-height: 150px;
   max-height: 200px;
   min-width: 200px;
  max-width: 300px;

    align-items: center;
    border-radius: 25px;
}

.title{
 
  font-family: Poppins, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}

.number{
    text-align: center;
  
    font-family: Poppins, sans-serif;
    font-weight: 900;
    font-size: 50px;
  }