.OTPboxesContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.OTPbox {
    --box-size: 40px;
    border: 1px solid grey;
    border-radius: calc(var(--box-size)/8);
    padding-left: calc(var(--box-size)/3);
    font-size: calc(var(--box-size)/2);
    background-color: white;
    color: black;
    height: var(--box-size);
    width: var(--box-size);
}
.OTPbox:hover {
    cursor: text;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}