@import '../../css/global.css';


.rootContainer {
    margin: 50px;
    margin-left: 10vw;
    margin-right: 10vw;
}

.buttonContainer {
    display: flex;
    justify-content: start;
    margin-bottom: 25px;

}

.activeCourseButton {
    font-family: Poppins;
    color: var(--color-darkblue);
    padding: 5px;
    margin-right: 5vw;
    font-weight: 500;
    border-radius: 5px;
    border:2px solid var(--color-pink);
    background-color: var(--color-pink);
}

.normalCourseButton {
    background-color: var(--color-white);
    font-family: Poppins;
    color: var(--color-darkblue);
    margin-right: 5vw;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
    border:2px solid var(--color-pink);
    

}