.allCardsContainer {
    overflow-x: hidden;
    overflow-y: auto;
}

.allCardsContainer h3 {
    margin-bottom: 0px;
    font-size: 25px;
}

.allCardsContainer>div {
    padding: 10px 20px;
}

.allCardsContainer p {
    margin: 0px;
}
.allCardsContainer p {
    margin: 0px;
}
.cardsContainerHeader {
    z-index: 2;
    background-color: #f5f5f5;
    white-space: nowrap;
    border-top: 1px solid darkgrey;
    position: sticky;
    top: 0px;
}
.placeholderText {
    margin: 20px 0px 0px 10px;
    color: grey;
}

@media screen and (max-width: 900px) {
    .allCardsContainer {
        max-height: calc(100vh - 231px);
    }
}
@media screen and (min-width: 900px) {
    .allCardsContainer {
        max-height: calc(100vh - 161px);
    }
}

@media screen and (max-width: 720px) {
    .cardsContainerHeader>h3 {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .placeholderText {
        text-align: center;
    }
}
@media screen and (min-width: 720px) {
    .xScrollableContainer {
        display: flex;
        justify-content: flex-start;
        background-color: inherit;
        overflow-x: auto;
        overflow-y: hidden;
    }
}