.batchDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: 500px;
    background-color: white;
}
.batchHeading{
    font-size: 36px;
    color: #ec78a6;
}
.selectBatch{
    margin-top: 10px;
    display: flex;
}

.selectBatch label{
    margin-right: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #a8a7a7;
}

.selectBatch select{
    width: 80px;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    border: none;
    background-color: #dbd8d8;
}
.detailTable{
    margin: 10px;
    margin-bottom: 20px;
}

.detailTable td{
    padding: 5px;
}

.detailTable label{
    font-size: 20px;
    font-weight: 500;
    color: #a8a7a7;
}

.detailTable input{
    height: 40px;
    width: 250px;
    font-size: px;
    padding: 10px;
    border-radius: 4px;
    border:none;
    background-color: #dbd8d8;
    outline: none;
}

.detailTable button{
    width: 40px;
    height: 40px;
    font-size: larger;
    font-weight: 500;
    text-transform: uppercase;
    padding: 3px;
    border-radius: 5px;
    color: #ec78a6;
    font-weight: bold;
    border: none;
}

.detailTable button:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.1s ease;

}
.detailTable td p{
    font-size: 18px;
    font-weight: 600;
    color: #a8a7a7;
}

@media screen and (max-width:450px){
    .batchDetails{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        justify-content: center;
        width: 200px;
        background-color: white;
    }
    .batchHeading{
        font-size: 14px;
        color: #ec78a6;
    }
    
    .selectBatch label{
        margin-right: 10px;
        font-size: 10px;
        font-weight: 500;
        color: #a8a7a7;
    }
    .selectBatch select{
        width: 50px;
        font-size: 8px;
        font-weight: 600;
        outline: none;
        border: none;
        background-color: #dbd8d8;
    }
    .detailTable label{
        font-size: 10px;
        font-weight: 500;
        color: #a8a7a7;
    }
    .detailTable input{
        height: 20px;
        width: 60px;
        font-size: 14px;
        padding: 10px;
        border-radius: 4px;
        border:none;
        background-color: #dbd8d8;
        outline: none;
    }
    .detailTable button{
        width: 20px;
        height: 20px;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 3px;
        border-radius: 5px;
        color: #ec78a6;
        font-weight: bold;
        border: none;
    }
    
    
}