.navWrapper {
  --nav-height: 70px;
  --phone-width: 720px;
  --nav-element-width: calc(calc(var(--phone-width)-120px)/6);

  width: 100vw;
  height: var(--nav-height);
  background-color: #2b324a;
  position: sticky;
  top: 0px;
  color: white;
  z-index: 3;
}

.logo {
  width: var(--nav-element-width);
  height: var(--nav-height);
  float: left;
}
.logo img {
  line-height: var(--nav-height);
  height: calc(var(--nav-height)/2);
  width: auto;
  margin: calc(var(--nav-height)/4) auto calc(var(--nav-height)/4) 30px;

}

@media only screen and (min-width: 900px) {
  .linkWrapper {
    display: flex;
    position: absolute;
    height: var(--nav-height);
    left: calc(50% - calc(calc(2*var(--nav-element-width))/2) - 120px);
   
  }

  .linkWrapper img {
    display: none;
  }

  .linkItem {
    text-decoration: none !important;
    color: white;
    font-weight: bold;
    width: var(--nav-element-width);
    height: var(--nav-height);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #2b324a;
    margin: 0px 20px;
  } 
  .linkItem:hover {
    color: #0dcfda;
  }

  .selected {
    border-bottom: 5px solid #0dcfda;
  }
}

@media only screen and (max-width: 900px) {
  .linkWrapper {
    position: fixed;
  
    bottom: 0px;
    background-color: #2b324a;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
    justify-content: space-evenly;
    width: 100vw;
    height: var(--nav-height);
  }
  
  .linkItem {
    text-decoration: none !important;
    color: white;
    font-weight: bold;
    width: calc(92%/4);
    height: var(--nav-height);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 5px solid #2b324a;
  } 
  .linkItem:hover {
    color: #0dcfda;
  }
  .linkWrapper img {
    display: none;
  }
  
  .selected {
    color: #0dcfda;
    border-top: 5px solid #0dcfda;
  }
}

.profileWrapper {
  width: var(--nav-element-width);
  height: var(--nav-height);
  float: right;
}

@media only screen and (max-width: 720px) {
  .profileWrapper {
    width: calc(var(--nav-element-width) - 30px);
    height: var(--nav-height);
    float: right;
    margin-right: 20px;
  }
}

.loginOrSignup{
  background: #0dcfda;
  width: calc(calc(3*var(--nav-element-width))/4);
  height: calc(var(--nav-height)/2);
  border-radius: 5px;
  margin-top: calc(var(--nav-height)/4);
  font-size: 0.7em;
  line-height: calc(var(--nav-height)/2);
  font-weight: bold;
  text-align: center;
}
.loginOrSignup:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.profileCircle{
  background: #0dcfda;
  line-height: calc(calc(3*var(--nav-height))/4);
  height: calc(calc(3*var(--nav-height))/4);
  width: calc(calc(3*var(--nav-height))/4);
  border-radius: 50%;
  margin: calc(var(--nav-height)/8) 0px calc(var(--nav-height)/8) 30px;
  font-weight: bold;
  font-size: 2rem;
  text-transform: capitalize;
  text-align: center;
}
.profileCircle:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.profileImgWrapper{
  height: calc(calc(3*var(--nav-height))/4);
  width: calc(calc(3*var(--nav-height))/4);
  margin: calc(var(--nav-height)/8) 0px calc(var(--nav-height)/8) 30px;
}
.profileImgWrapper img{
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.profileImgWrapper:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.dropdown{
  position: fixed;
  top: calc(var(--nav-height) + 10px);
  right: 20px;
  /* left: calc(100% - calc(2*var(--nav-element-width)) - 20px); */
  height: 50px;
  width: 150px;
  background: #0dcfda;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.dropdownItem {
  text-decoration: none !important;
  color: white;
  font-weight: bold;
  width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.dropdownItem img {
  display: none;
}
.dropdownItem:hover {
  cursor: pointer;
  color: black;
  transform: scale(1.05);
  transition: 0.1s ease-in-out;
}