@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  box-sizing: border-box;
}

.header{
    /* background-color: red; */
    height: 45px;
    width: 100%;
    text-align: center;
}
.Login_login__1D85U{
    /* background: url('../../images/blue\ bg.png');
    background-size: cover;
    background-repeat: no-repeat; */
    background: #121c38;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 100vh;
}
.Login_loginLeft__hgDfu{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.Login_loginLeft__hgDfu img{
    object-fit: contain;
    width: 250px;
}
.Login_loginLeftDetails__1BBgU{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 30px;
    color: white;
}
.Login_loginLeftDetails__1BBgU h1{
    font-size: 40px;
    padding-bottom: 3px;
    border-bottom: 2px solid white;
}
.Login_loginLeftDetails__1BBgU h3{
    font-size: 29.9px;
}
.Login_loginContainer__25ODG{
    position: relative;
    color: #ec78a6;
    background-color: #fff;
    /* padding: 20px 40px; */
    display: -webkit-flex;
    display: flex;
    border-radius: 30px;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* gap: 50px;  */
    height: 430px;
    width: 450px;
}
.Login_userImage__v_yvb{
    width: 100%;
    position: relative;
    height: 90px;
    background-color: #6ae4ed;
    box-shadow: 0px 10px 30px  #121c3869;
    border-radius: 30px;
    /* background: url('../../images/wUntitled-1.png') no-repeat center center/cover; */
}

.Login_userImage__v_yvb img{
    width: 100px;
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.Login_userImage__v_yvb svg{
    color: rgb(243, 241, 241);
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.Login_loginContainer__25ODG form{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    padding: 20px 40px;
}
.Login_inputDetails__baaal{
    background-color: lightgray;
    border-radius: 10px;
    box-shadow: 5px 5px 7px 2px rgb(167, 166, 166);
    -webkit-align-self: center;
            align-self: center;
}
.Login_inputDetails__baaal input{
    background-color: transparent;
    border: none;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    outline: none;
    font-size: 16px;
    font-weight: 600;
}
.Login_loginButton__3HrbB{
    -webkit-align-self: center;
            align-self: center;
    width: 120px;
    height: 30px;
    background-color: #ec78a6;
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

}

@media screen and (max-width:750px) {
    .Login_login__1D85U{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }
    .Login_loginLeft__hgDfu{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }
    .Login_loginLeft__hgDfu img{
        width:150px
    }
    .Login_loginLeftDetails__1BBgU h1{
        font-size: 18px;
    }
    .Login_loginLeftDetails__1BBgU h3{
        font-size: 12px;
    }
    .Login_loginContainer__25ODG{
        width: 200px;
        height: 200px;
    }
    .Login_userImage__v_yvb{
        height: 45px;
        position: relative;
        width: 100%;
        background: url(/static/media/wUntitled-1.11efce9a.png) no-repeat center center/cover;
    }
    .Login_userImage__v_yvb img{
        width: 30px;
        position: absolute;
        bottom: -50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .Login_userImage__v_yvb svg{
        width: 30px;
        height: 30px;
    }
    .Login_loginContainer__25ODG form{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        -webkit-align-items: center;
                align-items: center;
        height: 100%;
        padding: 5px 10px;
    }
    .Login_loginContainer__25ODG form h1{
        font-size: 14px;
    }
    .Login_inputDetails__baaal{
        background-color: lightgray;
        border-radius: 10px;
        box-shadow: 5px 5px 7px 2px rgb(167, 166, 166);
        -webkit-align-self: center;
                align-self: center;
    }
    .Login_inputDetails__baaal input{
        background-color: transparent;
        border: none;
        width: 100%;
        height: 10px;
        padding-left: 10px;
        outline: none;
        font-size: 8px;
        font-weight: 600;
    }
    .Login_loginButton__3HrbB{
        -webkit-align-self: center;
                align-self: center;
        width: 100px;
        height: 20px;
        background-color: #ec78a6;
        border: none;
        color: white;
        font-size: 8px;
        font-weight: 600;
        cursor: pointer;
    
    }
}

@media screen and (max-width:1200px) {
    .Login_login__1D85U{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}


:root {
    --color-darkblue: #2B324A;
    --color-white:#ffffff;
    --color-semiwhite:#f5f5f5;
    --color-blue:#64D8E0;
    --color-pink:#E383AA;
    --color-blue-opacity50 :#64D8E04B;
    --color-blue-opacity10 :#64D8E0FF;
    --colr-transparent:#00000000;
  }
* {
    margin: 0px;
}

.LoginForm_form-control__3tiFx {
    width: 100%;
}

.LoginForm_modalBackdrop__2Jine {
    position: fixed;
    top: 70px;
    left: 0px;
    display: -webkit-flex;
    display: flex;
    background-color: #f5f5f5;
    height: calc(100vh - 70px);
    width: 100vw;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 20px;
    overflow-y: auto;
}

.LoginForm_modal__1aei4 {
    --modal-width: 1080px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    box-shadow: 0px 0px 5px 5px lightgrey;
    text-align: center;
    width: 95%;
    height: 95%;
    max-width: var(--modal-width);
    max-height: 580px;
    overflow-y: auto;
}

.LoginForm_modalLeftSideBlue__2Dci7, .LoginForm_modalLeftSideWhite__2x3K0 {
    width: calc(calc(var(--modal-width)*11)/20);
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.LoginForm_modalLeftSideBlue__2Dci7 {
    background-color: var(--color-darkblue);
    color: white;
}

.LoginForm_modalLeftSideWhite__2x3K0 {
    background-color: white;
    color: var(--color-darkblue);
}

.LoginForm_leftSideContentContainer__xcJxv {
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.LoginForm_leftSideTextContainer__2mplY {
    padding: 0px 80px;
}
.LoginForm_leftSideTextContainer__2mplY>p {
    font-size: 0.5rem;
}

.LoginForm_leftSideImgContainer__1dJgz {
    max-width: 400px;
    max-height: 350px;
}
.LoginForm_leftSideImgContainer__1dJgz img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.LoginForm_modalRightSideBlue__1C3RH, .LoginForm_modalRightSideWhite__3iiCK {
    width: calc(calc(var(--modal-width)*9)/20);
    height: 100%;
    padding: 0px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    overflow-y: auto;
}

.LoginForm_modalRightSideBlue__1C3RH {
    background-color: var(--color-darkblue);
    color: white;
}

.LoginForm_modalRightSideWhite__3iiCK {
    background-color: white;
    color: var(--color-darkblue);
}

.LoginForm_modalRightSideContentContainer__1CymQ {
    width: 350px;
    height: 100%;
    max-width: 100%;
    padding: 40px 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.LoginForm_backBtnContainer__3zPEO {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    
}

.LoginForm_backBtn__1qc-n {
    font-size: small;
    color: var(--color-darkblue);
}

.LoginForm_backBtn__1qc-n:hover {
    cursor: pointer;
    
}

.LoginForm_modalFormContainer__3bV3_ {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: calc(100% - 50px);
    width: 80%;
}
.LoginForm_modalFormGetPhoneContainer__3iQv8{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: calc(100% - 50px);
    width: 80%;
}

.LoginForm_phoneNumberContainer__34sq4 {
    margin-left: -10px;
}

.LoginForm_modalForm__llUo3 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
}

.LoginForm_mildText__zyETS {
    color: darkgrey;
    font-size: 10px;
    margin-top: -2vh;
}
.LoginForm_warnText__1BGT- {
   
    font-size: 10px;
    color: red;
}


.LoginForm_linkBtn__3ue2V {
    text-decoration: underline;
}
.LoginForm_linkBtn__3ue2V:hover {
    cursor: pointer;
}

/* .customInput {
    border: 1px solid black;
    margin-top: 10px;
    border-radius: 10px;
    background-color: white;
    color: black;
}
.customInput input {
    height: 35px;
    padding-left: 10px;
    border-radius: 0px 10px 10px 0px;
    border: none;
    color: black;
    font-size: 17px;
}
.customInput span {
    height: 35px;
    padding-left: 10px;
    border-radius: 10px 0px 0px 10px;
} */

.LoginForm_modalForm__llUo3 button {
    background-color: var(--color-blue);
    color: black;
    border-radius: 10px;
}

.LoginForm_modalForm__llUo3 button:hover {
    background-color: var(--color-blue);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

@media screen and (max-width: 900px) {
    .LoginForm_modalBackdrop__2Jine {
        height: calc(100vh - 140px);
    }
}

@media screen and (max-width: 850px) {
    .LoginForm_modalRightSideBlue__1C3RH {
        padding: 0px;
    }
}
@media screen and (max-width: 820px) {
    .LoginForm_modal__1aei4 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        width: 95%;
        height: 100%;
        background-color: white;
        max-height: none;
        max-width: none;
        overflow-x: auto;
        overflow-y: auto;
    }

    .LoginForm_modalLeftSideBlue__2Dci7, .LoginForm_modalLeftSideWhite__2x3K0 {
        width: 100%;
        height: 50%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .LoginForm_leftSideTextContainer__2mplY {
        padding: 0px 20px;
    }
    .LoginForm_leftSideTextContainer__2mplY>p{
        font-size: 0.5rem;
    }
    .LoginForm_leftSideImgContainer__1dJgz {
        max-width: none;
        max-height: none;
        height: calc(100% - 60px);
    }

    .LoginForm_modalRightSideBlue__1C3RH, .LoginForm_modalRightSideWhite__3iiCK {
        width: 100%;
        height: 50%;
        padding: 0px 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .LoginForm_modalRightSideContentContainer__1CymQ {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding: 0px;
    }

   
   
}
@media screen and (max-width: 360px) {
    .LoginForm_modalRightSideWhite__3iiCK, .LoginForm_modalRightSideBlue__1C3RH {
        padding: 0px;
    }
}
@media screen and (max-width: 820px) {
    .LoginForm_modalBackdrop__2Jine {
        padding: 0px;
    }
    .LoginForm_modal__1aei4 {
        width: 100%;
        margin: 0px;
        height: 100%;
    }
    .LoginForm_modalLeftSideBlue__2Dci7, .LoginForm_modalLeftSideWhite__2x3K0 {
        background-color: #f5f5f5;
        height: 50%;
        color: black;
    }
    .LoginForm_modalRightSideBlue__1C3RH, .LoginForm_modalRightSideWhite__3iiCK {
        color: black;
        background-color: #f5f5f5;
        height: 50%;
    }

    .LoginForm_modalFormContainer__3bV3_ {
        display: inline;
        
    }

 
    
    .LoginForm_modalForm__llUo3 {
        position: relative;
        top:0px;
        grid-gap:5px;
        gap:5px;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        
    }
}
.OTPinput_OTPboxesContainer__2gsXP {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
}
.OTPinput_OTPbox__3GlkV {
    --box-size: 40px;
    border: 1px solid grey;
    border-radius: calc(var(--box-size)/8);
    padding-left: calc(var(--box-size)/3);
    font-size: calc(var(--box-size)/2);
    background-color: white;
    color: black;
    height: var(--box-size);
    width: var(--box-size);
}
.OTPinput_OTPbox__3GlkV:hover {
    cursor: text;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.events{
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    padding: 20px;
    -webkit-flex-direction: column;
            flex-direction: column;

}
.eventTitle{
    color: #0c4e89;
    font-size: 56px;
}
.batchbutton{
    height: 100px;
    width: 100px;
    margin: 10px;
}
.eventstype{
    font-size: 20px;
    font-weight: 900;
    color: rgb(1, 1, 68);
}
.futureEventsComingSoon{
    display: -webkit-flex;
    display: flex;
    margin-top: 30px;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.eventscompleted{
    display: -webkit-flex;
    display: flex;
    overflow-x: scroll;
}
.eventscompleted::-webkit-scrollbar {
    background: rgba(136, 230, 242, 0.563);
    height: 0.5em;
}
.eventscompleted::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  height: 1px;
  border-radius: 10px;
  outline: 1px solid slategrey;
}





@media screen and(max-width:850px) {
    .eventTitle{
        font-size: 32px;
    }
}

.button{
    /* border: 1px solid black; */
    width: 220px;
    height: 220px;
    background-color: #f1b6ce;
    cursor: pointer;
    position: relative;
    display: -webkit-flex;
    display: flex;
    border-radius: 10px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.button img{
    z-index: 5;
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.button h1{
    color:#f272a6 ;
}
.arrow{
    width: 150px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.arrow span:first-child{
    width: 5px;
    height: 5px;
    background-color: #f272a6;
    margin-left: 10px;
}
.arrow span{
    width: 10px;
    height: 5px;
    background-color: #f272a6;
    margin-left: 10px;
}
.arrowIcon{
    width: 60px;
    height: 50px;
    color: #f272a6;
}


.buttonEven{
    /* border: 1px solid black; */
    width: 220px;
    height: 220px;
    background-color: rgb(191, 231, 245);
    cursor: pointer;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-radius: 10px;
    -webkit-align-items: center;
            align-items: center;
}
.buttonEven img{
    z-index: 5;
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.buttonEven h1{
    color:#08b0c2 ;
}
.arrowEven{
    width: 150px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.arrowEven span:first-child{
    width: 5px;
    height: 5px;
    background-color: #08b0c2;
    margin-left: 10px;
}
.arrowEven span{
    width: 10px;
    height: 5px;
    background-color: #08b0c2;
    margin-left: 10px;
}
.arrowIconEven{
    width: 60px;
    height: 50px;
    color: #08b0c2;
}

@media screen and(max-width:850px) {
    .button{
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
    }
    .buttonEven{
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
    }
}


@media screen and (max-width:1200px) {
    .button{
        width: 150px;
        height: 150px;
    }
    .button img{
        width: 60px;
        height: 60px;
    }
    .buttonEven{
        width: 150px;
        height: 150px;
    }
    .buttonEven img{
        width: 60px;
        height: 60px;
    } 
}

/* .eventComponent{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background: ;
} */

.eventComponentName{
    width: 300px;
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #dddddd;
    box-shadow: 1px 5px 10px 1px rgb(99, 99, 99);
    margin: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.futureComponetName{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.eventname{
        font-size: 20px;
        text-align: center;
        color: #242626;   
}
.imagecontainer{
    background: rgb(248, 248, 244);
    margin: 10px;
    height: 100px;
    width: 100%;
}
.evenButton{
    background-color: 3px solid lightgray;
    color: #08b0c2;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;
}
.oddButton{
    background-color: 3px solid lightgray;
    color: #f272a6;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;
}
.evenButton:hover{
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}
.oddButton:hover{
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.eventTypeButtonsoverlay{
    position: absolute;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    top: 0;
    left: 0;
    margin: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.419);
    width: 100%;
    height: 100%;
    z-index: 10;
}
.even{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.doubleclick{
      position: absolute;
      color: white;
          margin-top: 15%;
}
.imgcontainer{
    width: 100%;
    height: 100px;
    margin-top: 10px;
}
.imgcontainer img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    box-shadow: 0 10px 10px rgba(85, 90, 90, 0.289) inset;
}
.eventComponentName:hover .imgcontainer img {
    background-color: wheat;
}

.closebutton{
    height: 50px;
    width: 50px;
    font-weight: 900;
    position: absolute;
    margin-left: 1%;
    margin-top: 10%;
    text-align: center;
    border-radius: 50%;
    padding-top: 15px;
    background: rgb(238, 238, 238);
}
.eventTypeButtons{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 0.8 1;
            flex: 0.8 1;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.futureComponent{
    border: 1px solid black;
    display: -webkit-flex;
    display: flex;
}


@media screen and (max-width:1200px) {
    .eventComponentName{
        width: 200px;
        height: 200px;
    }
    
    .even{
        font: 24px;
    }
    .odd{
        font-size: 24px;
    }
}

@media screen and (max-width:850px) {
    .eventComponent{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        height: 100%
    }
    .eventTypeButtons{
        margin-top: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex: 1 1;
                flex: 1 1;
    }
    .eventComponent::after{
        content: "-";
        background-color: black;
        height: 3px;
        width: 100%;
    }
}
.loader{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
    /* border: 1px solid black; */
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.spinner{
    width: 200px;
    height: 200px;
}
.batchDetails{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 10px;
    gap: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    width: 500px;
    background-color: white;
}
.batchHeading{
    font-size: 36px;
    color: #ec78a6;
}
.selectBatch{
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
}

.selectBatch label{
    margin-right: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #a8a7a7;
}

.selectBatch select{
    width: 80px;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    border: none;
    background-color: #dbd8d8;
}
.detailTable{
    margin: 10px;
    margin-bottom: 20px;
}

.detailTable td{
    padding: 5px;
}

.detailTable label{
    font-size: 20px;
    font-weight: 500;
    color: #a8a7a7;
}

.detailTable input{
    height: 40px;
    width: 250px;
    font-size: px;
    padding: 10px;
    border-radius: 4px;
    border:none;
    background-color: #dbd8d8;
    outline: none;
}

.detailTable button{
    width: 40px;
    height: 40px;
    font-size: larger;
    font-weight: 500;
    text-transform: uppercase;
    padding: 3px;
    border-radius: 5px;
    color: #ec78a6;
    font-weight: bold;
    border: none;
}

.detailTable button:hover{
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.1s ease;

}
.detailTable td p{
    font-size: 18px;
    font-weight: 600;
    color: #a8a7a7;
}

@media screen and (max-width:450px){
    .batchDetails{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 10px;
        gap: 10px;
        -webkit-justify-content: center;
                justify-content: center;
        width: 200px;
        background-color: white;
    }
    .batchHeading{
        font-size: 14px;
        color: #ec78a6;
    }
    
    .selectBatch label{
        margin-right: 10px;
        font-size: 10px;
        font-weight: 500;
        color: #a8a7a7;
    }
    .selectBatch select{
        width: 50px;
        font-size: 8px;
        font-weight: 600;
        outline: none;
        border: none;
        background-color: #dbd8d8;
    }
    .detailTable label{
        font-size: 10px;
        font-weight: 500;
        color: #a8a7a7;
    }
    .detailTable input{
        height: 20px;
        width: 60px;
        font-size: 14px;
        padding: 10px;
        border-radius: 4px;
        border:none;
        background-color: #dbd8d8;
        outline: none;
    }
    .detailTable button{
        width: 20px;
        height: 20px;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 3px;
        border-radius: 5px;
        color: #ec78a6;
        font-weight: bold;
        border: none;
    }
    
    
}
.participantTable{
    margin: 30px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    padding: 10px;
}
.participantTable th{
    border-right: 5px solid lightgray;
    color: #ec78a6;
    text-transform: capitalize;
}
.participantTable th:last-child{
    border-right: none;
}

.participantTable table{
    
    border-collapse: collapse;
}
.participantTable td{
    border-top: 5px solid lightgray;
    border-right: 5px solid lightgray;
    color: #ec78a6;
    text-transform: capitalize;

}
.participantTable td:last-child{
    border-right: none;
}

.participantTable th,td{
    padding: 5px;
}

.participantTable tr select{
    background-color: rgb(191, 231, 245);
    border: none;
    padding: 5px;
    font-size: 14px;
    color: #ec78a6;
    font-weight: 600;
    outline: none;
}

.participantTable tr button{
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ec78a6;
    border: none;
    padding: 5px;
    background-color: rgb(191, 231, 245);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.eventPage{
    /* background: url('../../images/white\ bg.png');
    background-size: cover;
    background-repeat: no-repeat; */
    background: rgb(177, 35, 19);
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    z-index: 3;
    border: 100px bold turquoise;
}
.eventBackGround{
    width: 500px;
    height: 150px;
    display: grid;
    position: relative;
    place-items: center;
}
.eventBackGround::before{
    content: "";
    /* background: url('../../images/wUntitled-1.png') no-repeat center center;
    background-size: cover; */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

}
.eventBackGround h1{
    color: white;
    font-size: 36px;
}
.eventBackGround h2{
    color: white;
    font-size: 32px;
    font-weight: normal;
}
.categoryName{
    width: 400px;
    height: 150px;
    position: relative;
    display: grid;
    place-items: center;
}
.categoryName::before{
    content: "";
    /* background: transparent url('../../images/wUntitled-1.png') no-repeat center center;
    background-size: cover; */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.categoryName h2{
    color: white;
    font-size: 32px;
    font-weight: normal;
}

.batchCreation{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 100%;
    position: relative;
    
}

.createBatch{
    padding: 20px 0;    
    background-color: white;
    width: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: 40vh;
}

.createBatch>h2{
    font-size: 32px;
    text-transform: uppercase;
    color: #ec78a6;
}
.batchInput{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 60%;
    position: relative;
    z-index: 2;
}
.batchInput label{
    font-size: 20px;
    font-weight: 400;
}
.batchInput input{
    width: 35px;
    outline: none;
    height: 35px;
    font-size: 18px;
    font-weight: 700;
    background-color: lightgray;
    border: none;
    
}
.batchInput input::-webkit-outer-spin-button,
.batchInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
.batchInput input[type=number] {
  -moz-appearance: textfield;
}

.createButton{
   background-color: #ec78a6;
   border: none;
   width: 150px;
   height: 30px;
   color: white;
   font-size: 16px;
   cursor: pointer;
}

@media screen and (max-width:450px) {
    .eventBackGround{
        width: 200px;
        height: 50px;
        display: grid;
        position: relative;
        place-items: center;
    }
    .eventBackGround h1{
        color: white;
        font-size: 14px;
    }
    .eventBackGround h2{
        color: white;
        font-size: 12px;
        font-weight: normal;
    }
    .categoryName{
    
        width: 200px;
        height: 50px;
        position: relative;
        display: grid;
        place-items: center;
    }
    .categoryName h2{
        color: white;
        font-size: 16px;
        font-weight: normal;
    }
    .createBatch{
        padding: 20px 0;    
        background-color: white;
        width: 200px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        height: 40vh;
    }
    .createBatch>h2{
        font-size: 14px;
        text-transform: uppercase;
        color: #ec78a6;
    }
    .batchInput label{
        font-size: 10px;
        font-weight: 400;
    }
    
}
.CardsDisplay_allCardsContainer__3-WTl {
    overflow-x: hidden;
    overflow-y: auto;
}

.CardsDisplay_allCardsContainer__3-WTl h3 {
    margin-bottom: 0px;
    font-size: 25px;
}

.CardsDisplay_allCardsContainer__3-WTl>div {
    padding: 10px 20px;
}

.CardsDisplay_allCardsContainer__3-WTl p {
    margin: 0px;
}
.CardsDisplay_allCardsContainer__3-WTl p {
    margin: 0px;
}
.CardsDisplay_cardsContainerHeader__3TsXb {
    z-index: 2;
    background-color: #f5f5f5;
    white-space: nowrap;
    border-top: 1px solid darkgrey;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}
.CardsDisplay_placeholderText__1olw3 {
    margin: 20px 0px 0px 10px;
    color: grey;
}

@media screen and (max-width: 900px) {
    .CardsDisplay_allCardsContainer__3-WTl {
        max-height: calc(100vh - 231px);
    }
}
@media screen and (min-width: 900px) {
    .CardsDisplay_allCardsContainer__3-WTl {
        max-height: calc(100vh - 161px);
    }
}

@media screen and (max-width: 720px) {
    .CardsDisplay_cardsContainerHeader__3TsXb>h3 {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .CardsDisplay_placeholderText__1olw3 {
        text-align: center;
    }
}
@media screen and (min-width: 720px) {
    .CardsDisplay_xScrollableContainer__2C6mK {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        background-color: inherit;
        overflow-x: auto;
        overflow-y: hidden;
    }
}
/* .moduleCardWrapper {
    display: inline-block;
} */

.LearningModuleCard_moduleCard__3von- {
    display: inline-block;
    position: relative;
    margin: 0px 10px 10px 0px;
    /* aspect ratio - 3:4.5 () */
    padding: 15px;
    color: white;
    background-color: #2b324a;
    border-radius: 25px;
    text-decoration: none;
    text-align: center;
    /* aspect ratio - 3:4.5 () */
    width: 300px;
    height: 359px;
}

.LearningModuleCard_moduleCardImage__3hUFh {
    margin-top: 10px;
    object-fit: contain;
    background-color: black;
    /* aspect ratio - 4:3 */
    width: 270px;
    height: 180px;
}

.LearningModuleCard_moduleCardDescription__JJJUM {
    display: inline-block;
    text-align: center;
    width: 100%;
    overflow-y: auto;
    margin-top: 20px;
    height: calc(100% - 310px);
}

.LearningModuleCard_moduleCard__3von-:hover {
    cursor: pointer;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    transition: 0.2s ease-in-out;
}

.LearningModuleCard_moduleCard__3von-:hover .LearningModuleCard_moduleCardImage__3hUFh {
    opacity: 0.6;
}

.LearningModuleCard_moduleName__2X278 {
    text-align: center;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    overflow-y: hidden;
}

.LearningModuleCard_tutorPreviewContainer__3-QC1 {
    position: absolute;
    left: calc(50% - 65px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    bottom: -90px;
    text-align: center;
    z-index: 1;
    color: black;
}

.LearningModuleCard_tutorPreviewContainer__3-QC1:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: 0.1s ease-in-out;
}

.LearningModuleCard_tutorImgContainer__6y41r {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: black;
}

.LearningModuleCard_tutorImgContainer__6y41r:hover .LearningModuleCard_tutorImg__shVT8 {
    opacity: 0.7;
}

.LearningModuleCard_tutorName__uVeDe {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    overflow-y: hidden;
    color: white;
}

.LearningModuleCard_tutorImg__shVT8 {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.LearningModuleCard_tutorRating__VXWTq {
    margin-top: 10px;
}

@media screen and (max-width:720px) {
    .LearningModuleCard_moduleCard__3von- {
        width: 100%;
        height: 50vw;
        margin-bottom: 90px;
    }
    .LearningModuleCard_moduleName__2X278 {
        margin-bottom: 15px;
    }
    .LearningModuleCard_moduleCardImage__3hUFh {
        width: calc(50% - 10px);
        height: calc(50vw - 125px);
        margin: 0px;
        float: left;
    }
    .LearningModuleCard_moduleCardDescription__JJJUM {
        float: right;
        width: calc(50% - 10px);
        height: calc(100% - 120px);
    }
    .LearningModuleCard_tutorPreviewContainer__3-QC1 {
        bottom: -70px;
    }
    .LearningModuleCard_tutorImgContainer__6y41r {
        height: 75px;
        width: 75px;
    }
}

@media screen and (max-width:500px) {
    .LearningModuleCard_moduleCard__3von- {
        margin-bottom: 80px;
    }
    .LearningModuleCard_moduleCardDescription__JJJUM {
        margin-top: 5px;
        float: right;
        width: calc(50% - 10px);
        height: calc(100% - 100px);
    }
    .LearningModuleCard_tutorPreviewContainer__3-QC1 {
        bottom: -60px;
    }
    .LearningModuleCard_tutorImgContainer__6y41r {
        height: 50px;
        width: 50px;
    }
}
.MyEventsPage_MyEventsPage__3CN-a {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 10px 60px;
}
.MyEventsPage_createEvent__3ASD2{
    padding: 20px 40px;
}

.MyEventsPage_createEventButton__1e8-F{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
}

.MyEventsPage_uncompletedEvents__2fzBI{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}

.MyEventsPage_uncompletedEventsList__1G4Xm{
    background-color: #2b324a;
    padding: 30px 10px;
    color: white;
    font-size: 22px;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;

}

.MyEventsPage_unActiveEvents__2usuX, .MyEventsPage_activeEvents__29M6l{
    margin: 30px 0;

}

@media screen and (max-width: 720px) {
    .MyEventsPage_EventPage__11_a-{
        padding: 20px 0;
    }
    .MyEventsPage_createEvent__3ASD2{
        padding: 20px 0;
    }

    .MyEventsPage_uncompletedEvents__2fzBI{
        -webkit-justify-content: center;
                justify-content: center;
        
    }
    .MyEventsPage_uncompletedEventsList__1G4Xm{
        min-width: 90vw;
    }
}
.CreateEvent_CreateEvent__1Klug {
    margin: 50px 30px;
  }
  
  .CreateEvent_header__d8dU2 {
    margin-bottom: 20px;
  }
  .CreateEvent_formnav__16UAT {
    list-style-type: none;
    margin-left: 0 !important;
    margin-bottom: 20px;
  }
  .CreateEvent_formnav__16UAT li {
    display: inline;
    margin-right: 50px;
  }
  
  .CreateEvent_textfield__EuXF5 {
    width: 100%;
    border-radius: 5px;
  }
  .CreateEvent_unactiveli__1sivC {
    padding: 2px;
  }
  .CreateEvent_active__1oUeC {
    border-bottom: 4px solid #64d8e0;
    padding: 2px;
  }
  
  .CreateEvent_submitbutton__17VBv {
    background-color: #64d8e0;
    border: none;
    padding: 10px 20px;
    font-size: 22px;
    border-radius: 8px;
    color: black;
    text-transform: uppercase;
  }
  .CreateEvent_SetupForm__3cz31 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 50px;
    gap: 50px;
  }
  .CreateEvent_orgSetup__32cy7 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-gap: 50px;
    gap: 50px;
  }
  .CreateEvent_priceSetup__13HYD {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    grid-gap: 50px;
    gap: 50px;
  }
  
  .CreateEvent_leftSetup__3I6GB {
    -webkit-flex-basis: 800px;
            flex-basis: 800px;
  }
  
  .CreateEvent_rightSetup__vh1UT {
    -webkit-flex-basis: 350px;
            flex-basis: 350px;
  }
  
  .CreateEvent_buttondiv__cR0cs {
    margin: 10px 50px;
  }
  
  .CreateEvent_textfieldcourseDetails__WN03X {
    height: 40vh;
    width: 500px;
  }
  
  .CreateEvent_courseHighlightsContent__1mT1f {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
  }
  
  .CreateEvent_courseHighlightsContent__1mT1f div {
    -webkit-flex-basis: 45%;
            flex-basis: 45%;
  }
  
  .CreateEvent_paracourseHL__X57iW {
    margin: 10px 0px;
    font-size: 17px;
    line-height: 26px;
  }
  
  .CreateEvent_paralinescourseHL__2c2LZ {
    font-size: 17px;
    line-height: 26px;
  }
  
  input[type="file"]::file-selector-button {
    border: 2px solid #f085b0;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #f085b0;
    transition: 1s;
    font-size: 22px;
  }
  
  input[type="file"]::file-selector-button:hover {
    border: 2px solid #cc5577;
  }
  .CreateEvent_fileInput__1Bgom {
    border: 1px solid black;
    padding: 5px 10px;
    width: 90%;
    margin: 5px 0px;
    border-radius: 5px;
  }
  .CreateEvent_addInstructor__hC6Ce{
    margin-bottom: 50px;
  }
  .CreateEvent_addInstructorUpper__3E31Y {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;
  }
  
  .CreateEvent_addInstructorUpper__3E31Y div {
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
  
  progress {
    display: block;
    width: 90%;
  }
  
  
  .CreateEvent_addTopic__1r4Nh,.CreateEvent_faq__2PipA{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .CreateEvent_addTopicForm__3vbX5, .CreateEvent_addedTopic__1rfVC,.CreateEvent_faqForm__97L_I,.CreateEvent_addedFAQs__6bxl6{
    -webkit-flex-basis: 47%;
            flex-basis: 47%;
  }
  
  .CreateEvent_addedTopicList__1f23B, .CreateEvent_listOfFAQs__3F5W3{
    padding: 10px 0;
    margin-top: 10px;
    background-color: white;
    min-height: 34vh;
    max-height: 35vh;
    overflow-y: scroll;
    border: 1px solid gainsboro;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }
  .CreateEvent_highLightsList__1bfD1{
    padding: 10px 0;
    margin: 10px 0;
    background-color: white;
    min-height: 14vh;
    max-height: 16vh;
    overflow-y: scroll;
    border: 1px solid gainsboro;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }
  
  
  
  
  @media only screen and (max-width: 600px) {
    .CreateEvent_unactiveli__1sivC {
    display: none !important;
    }
  
    .CreateEvent_active__1oUeC{
      text-align: center;
    }
  
    .CreateEvent_addInstructorUpper__3E31Y div{
      -webkit-flex-grow: 1;
              flex-grow: 1;
    }
    .CreateEvent_courseHighlightsContent__1mT1f div {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-basis: 100%;
              flex-basis: 100%
    }
    .CreateEvent_buttondiv__cR0cs {
      margin-top: 10px;
      padding-bottom: 50px;
      margin-right: 0;
      margin-left: 0;
    }
    .CreateEvent_submitbutton__17VBv{
      font-size: 20px;
    }
  
    .CreateEvent_leftSetup__3I6GB {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-basis: 100%;
              flex-basis: 100%
    }
    
    .CreateEvent_rightSetup__vh1UT {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-basis: 100%;
              flex-basis: 100%
    }
    .CreateEvent_SetupForm__3cz31{
      grid-gap:10px;
      gap:10px;
    }
  
    .CreateEvent_addTopicForm__3vbX5, .CreateEvent_addedTopic__1rfVC,.CreateEvent_faqForm__97L_I,.CreateEvent_addedFAQs__6bxl6{
      -webkit-flex-basis: 100%;
              flex-basis: 100%;
      -webkit-flex-grow: 1;
              flex-grow: 1;
  
    }
  
    /* .addInstructorUpper {
    box-sizing: border-box;
  
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    } */
  
  
  
  }
.Card_cardview__3Gd8v{
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column; 
   -webkit-justify-content: center; 
           justify-content: center;
   box-shadow: 2px 2px #0000003F;
   width: 20vw;
  padding: 10px;
  margin: 10px;
   min-height: 150px;
   max-height: 200px;
   min-width: 200px;
  max-width: 300px;

    -webkit-align-items: center;

            align-items: center;
    border-radius: 25px;
}

.Card_title__T5qx6{
 
  font-family: Poppins, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}

.Card_number__1vr4L{
    text-align: center;
  
    font-family: Poppins, sans-serif;
    font-weight: 900;
    font-size: 50px;
  }
.Dashboard_cards__21VWw{
  
    display: -webkit-flex;
  
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.Dashboard_cardHolder__2nXB- {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--color-semiwhite);
}

.Dashboard_nameText__J0TSk {
    text-align: center;
    background-color: var(--color-semiwhite);
    color: var(--color-darkblue);
    padding-top: 20px;
    font-family: Poppins;
    font-weight: 900;
    font-size:xx-large;
}
.participantTable{
    margin: 30px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    padding: 10px;
}
.participantTable th{
    border-right: 5px solid lightgray;
    color: #ec78a6;
    text-transform: capitalize;
}
.participantTable th:last-child{
    border-right: none;
}

.participantTable table{
    
    border-collapse: collapse;
}
.participantTable td{
    border-top: 5px solid lightgray;
    border-right: 5px solid lightgray;
    color: #ec78a6;
    text-transform: capitalize;

}
.participantTable td:last-child{
    border-right: none;
}

.participantTable th,td{
    padding: 5px;
}

.participantTable tr select{
    background-color: rgb(191, 231, 245);
    border: none;
    padding: 5px;
    font-size: 14px;
    color: #ec78a6;
    font-weight: 600;
    outline: none;
}

.participantTable tr button{
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ec78a6;
    border: none;
    padding: 5px;
    background-color: rgb(191, 231, 245);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.eventPage {
	background: url("/static/media/white bg.20ed186e.png");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
	z-index: 3;
}
.eventBackGround {
	width: 500px;
	height: 150px;
	display: grid;
	position: relative;
	place-items: center;
}
.eventBackGround::before {
	content: '';
	background: url(/static/media/wUntitled-1.11efce9a.png) no-repeat center center;
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.tabsection {
	background: rgba(176, 177, 176, 0.145);
}
.tabs {
	width: 900px;
	margin-bottom: 100px;
	display: -webkit-flex;
	display: flex;
}
.tabs span {
	font-size: 20px;
	font-weight: 900;
	margin-left: 16%;
}
.rec {
	background-color: cyan;
	height: 5px;
	border-radius: 50px;
	width: 100%;
}
.tabarea {
	height: 100%;
	width: 500px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-left: 23%;
	-webkit-justify-content: center;
	        justify-content: center;
}
.eventBackGround h1 {
	color: white;
	font-size: 36px;
}
.eventBackGround h2 {
	color: white;
	font-size: 32px;
	font-weight: normal;
}
.categoryName {
	width: 400px;
	height: 150px;
	position: relative;
	display: grid;
	place-items: center;
}
.categoryName::before {
	content: '';
	background: transparent url(/static/media/wUntitled-1.11efce9a.png) no-repeat center
		center;
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.categoryName h2 {
	color: white;
	font-size: 32px;
	font-weight: normal;
}

.batchCreation {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	height: 100%;
	position: relative;
}

.createBatch {
	padding: 20px 0;
	background-color: white;
	width: 400px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	height: 40vh;
}

.createBatch > h2 {
	font-size: 32px;
	text-transform: uppercase;
	color: #ec78a6;
}
.batchInput {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	height: 60%;
	position: relative;
	z-index: 2;
}
.batchInput label {
	font-size: 20px;
	font-weight: 400;
}
.batchInput input {
	width: 35px;
	outline: none;
	height: 35px;
	font-size: 18px;
	font-weight: 700;
	background-color: lightgray;
	border: none;
}
.batchInput input::-webkit-outer-spin-button,
.batchInput input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/* Firefox */
.batchInput input[type='number'] {
	-moz-appearance: textfield;
}

.createButton {
	background-color: #ec78a6;
	border: none;
	width: 150px;
	height: 30px;
	color: white;
	font-size: 16px;
	cursor: pointer;
}

@media screen and (max-width: 450px) {
	.eventBackGround {
		width: 200px;
		height: 50px;
		display: grid;
		position: relative;
		place-items: center;
	}
	.eventBackGround h1 {
		color: white;
		font-size: 14px;
	}
	.eventBackGround h2 {
		color: white;
		font-size: 12px;
		font-weight: normal;
	}
	.categoryName {
		width: 200px;
		height: 50px;
		position: relative;
		display: grid;
		place-items: center;
	}
	.categoryName h2 {
		color: white;
		font-size: 16px;
		font-weight: normal;
	}
	.createBatch {
		padding: 20px 0;
		background-color: white;
		width: 200px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		height: 40vh;
	}
	.createBatch > h2 {
		font-size: 14px;
		text-transform: uppercase;
		color: #ec78a6;
	}
	.batchInput label {
		font-size: 10px;
		font-weight: 400;
	}
}

.Nav_navWrapper__1a07n {
  --nav-height: 70px;
  --phone-width: 720px;
  --nav-element-width: calc(calc(var(--phone-width)-120px)/6);

  width: 100vw;
  height: var(--nav-height);
  background-color: #2b324a;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  color: white;
  z-index: 3;
}

.Nav_logo__3an28 {
  width: var(--nav-element-width);
  height: var(--nav-height);
  float: left;
}
.Nav_logo__3an28 img {
  line-height: var(--nav-height);
  height: calc(var(--nav-height)/2);
  width: auto;
  margin: calc(var(--nav-height)/4) auto calc(var(--nav-height)/4) 30px;

}

@media only screen and (min-width: 900px) {
  .Nav_linkWrapper__1sk8o {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    height: var(--nav-height);
    left: calc(50% - calc(calc(2*var(--nav-element-width))/2) - 120px);
   
  }

  .Nav_linkWrapper__1sk8o img {
    display: none;
  }

  .Nav_linkItem__3Mz0S {
    text-decoration: none !important;
    color: white;
    font-weight: bold;
    width: var(--nav-element-width);
    height: var(--nav-height);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 5px solid #2b324a;
    margin: 0px 20px;
  } 
  .Nav_linkItem__3Mz0S:hover {
    color: #0dcfda;
  }

  .Nav_selected__2o4Mu {
    border-bottom: 5px solid #0dcfda;
  }
}

@media only screen and (max-width: 900px) {
  .Nav_linkWrapper__1sk8o {
    position: fixed;
  
    bottom: 0px;
    background-color: #2b324a;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 2%;
    gap: 2%;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 100vw;
    height: var(--nav-height);
  }
  
  .Nav_linkItem__3Mz0S {
    text-decoration: none !important;
    color: white;
    font-weight: bold;
    width: calc(92%/4);
    height: var(--nav-height);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-top: 5px solid #2b324a;
  } 
  .Nav_linkItem__3Mz0S:hover {
    color: #0dcfda;
  }
  .Nav_linkWrapper__1sk8o img {
    display: none;
  }
  
  .Nav_selected__2o4Mu {
    color: #0dcfda;
    border-top: 5px solid #0dcfda;
  }
}

.Nav_profileWrapper__2TpZc {
  width: var(--nav-element-width);
  height: var(--nav-height);
  float: right;
}

@media only screen and (max-width: 720px) {
  .Nav_profileWrapper__2TpZc {
    width: calc(var(--nav-element-width) - 30px);
    height: var(--nav-height);
    float: right;
    margin-right: 20px;
  }
}

.Nav_loginOrSignup__1mi3i{
  background: #0dcfda;
  width: calc(calc(3*var(--nav-element-width))/4);
  height: calc(var(--nav-height)/2);
  border-radius: 5px;
  margin-top: calc(var(--nav-height)/4);
  font-size: 0.7em;
  line-height: calc(var(--nav-height)/2);
  font-weight: bold;
  text-align: center;
}
.Nav_loginOrSignup__1mi3i:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.Nav_profileCircle__36soC{
  background: #0dcfda;
  line-height: calc(calc(3*var(--nav-height))/4);
  height: calc(calc(3*var(--nav-height))/4);
  width: calc(calc(3*var(--nav-height))/4);
  border-radius: 50%;
  margin: calc(var(--nav-height)/8) 0px calc(var(--nav-height)/8) 30px;
  font-weight: bold;
  font-size: 2rem;
  text-transform: capitalize;
  text-align: center;
}
.Nav_profileCircle__36soC:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.Nav_profileImgWrapper__1nG5D{
  height: calc(calc(3*var(--nav-height))/4);
  width: calc(calc(3*var(--nav-height))/4);
  margin: calc(var(--nav-height)/8) 0px calc(var(--nav-height)/8) 30px;
}
.Nav_profileImgWrapper__1nG5D img{
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.Nav_profileImgWrapper__1nG5D:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.Nav_dropdown__255Qe{
  position: fixed;
  top: calc(var(--nav-height) + 10px);
  right: 20px;
  /* left: calc(100% - calc(2*var(--nav-element-width)) - 20px); */
  height: 50px;
  width: 150px;
  background: #0dcfda;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Nav_dropdownItem__1kpCG {
  text-decoration: none !important;
  color: white;
  font-weight: bold;
  width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.Nav_dropdownItem__1kpCG img {
  display: none;
}
.Nav_dropdownItem__1kpCG:hover {
  cursor: pointer;
  color: black;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: 0.1s ease-in-out;
}
.navbar{
    width: 100%;
    position: static;
    padding-top: 1%;
    height: 70px;
    background: hsl(244, 41%, 7%)
}
.rectangle{
    background: cornsilk;
    /* position: absolute; */
    width: 50px;
    height: 20px;
}
.items{
    /* margin-left: 50%; */
    font-family: 'Roboto';
    text-align: center;
    font-size: 20px;
}
.items span{
    z-index: 1;
}
.items span{
    margin-left: 5%;
}
/* Style the tab */
.Tab_tab__2467W {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    background-color: var(--color-white);

}






.Tab_activebutton__3HFJm {
    outline: none;
    color: var(--color-darkblue);
    background: none;
    border:none;
    padding: 10px;
    font-weight: 500;
    border-bottom: 5px solid var(--color-blue);
    font-size: medium;
    font-family: Poppins, sans-serif;
    
    cursor: pointer;
}


.Tab_normalbutton__2-YZY {
    outline: none;
    color: var(--color-darkblue);
    background: none;
    border:none;
    padding: 10px;
    font-size: medium;
    font-family: Poppins, sans-serif;
    
    cursor: pointer;
}

.Tab_normalbutton__2-YZY:hover {
    color: var(--color-blue);
}








.StudentCard_cardPreview__U0QcX {
    display: -webkit-flex;
    display: flex;
    margin: 10px;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-family: Poppins;
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--color-semiwhite);
   border-left: 10px solid var(--color-blue);
}

.StudentCard_cardPreview__U0QcX:hover {
    border-left: 20px solid var(--color-pink);
}


.StudentCard_cardButton__2gR7B {
    background-color: var(--color-white);
    border-radius: 5px;
    border:2px solid var(--color-darkblue);
    padding-top: 5px;
    font-family: Poppins;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
}

.StudentCard_singleColumn__1YfbK {
    text-align: center;
   
    font-size: small;
    margin: 5px;
}

.StudentCard_profileColumn__1RzBU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: start;
    font-size: small;
    min-width: 20%;
    margin: 5px;
}

.StudentCard_evaluateButton__3lGLQ {
    margin: 5px;

    background-color: var(--color-darkblue);
    border-radius: 5px;
    border:2px solid var(--color-white);
    padding-top: 5px;
    
    color: var(--color-white);
    font-family: Poppins;
    padding-bottom: 5px;
    padding-left: 50px;
    padding-right: 50px;
}

.StudentCard_rating__1z17n {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: small;
}

.StudentCard_evaluateModalForm__2S8_m {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;

}

.StudentCard_evaluateModalLabel__337Pq {
    color:var(--color-darkblue);
    font-size: medium;
    font-weight: 500;
    padding: 20px;
    width: 50%;
}

.StudentCard_commentDescription__1YP0s {
    width: 200px;
    height: 100px;
    background-color: var(--color-semiwhite);
    border:none

}
.StudentCard_evaluateModalDiv__1t7xm {
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 20px;
}
  



@media only screen and (max-width: 1000px) {
 .StudentCard_cardPreview__U0QcX{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
     -webkit-justify-content: center;
             justify-content: center;
  }

  .StudentCard_profileColumn__1RzBU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    font-size: small;
    min-width: 25%;
    margin: 5px;
}




}
.EvaluateModal_rating__3LoMI {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: small;
}

.EvaluateModal_evaluateModalForm__34slD {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;

}

.EvaluateModal_evaluateModalLabel__11UZn {
    color:var(--color-darkblue);
    font-size: medium;
    font-weight: 500;
    padding: 20px;
    width: 50%;
}

.EvaluateModal_commentDescription__3HB44 {
    width: 200px;
    height: 100px;
    background-color: var(--color-semiwhite);
    border:none

}
.EvaluateModal_evaluateModalDiv__1pEMT {
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 20px;
}
  


.DialogModal_dialogModalContainer___Hrye {
    background-color: var(--color-semiwhite);
    
}

.AllStudents_cardHolder__1aSkl {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
}

.CurrentStudents_cardHolder__2ZTHn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
}
.RequestCard_cardPreview__1P9yW {
    display: -webkit-flex;
    display: flex;
    margin: 10px;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-family: Poppins;
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--color-semiwhite);
   border-left: 10px solid var(--color-blue);
}

.RequestCard_cardPreview__1P9yW:hover {
    border-left: 20px solid var(--color-pink);
}


.RequestCard_cardButton__2ncUS {
    background-color: var(--color-white);
    border-radius: 5px;
    border:2px solid var(--color-darkblue);
    padding-top: 5px;
    font-family: Poppins;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
}

.RequestCard_singleColumn__3XQqb {
    text-align: center;
   
    font-size: small;
    margin: 5px;
}

.RequestCard_profileColumn__1KopE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: start;
    font-size: small;
    min-width: 20%;
    margin: 5px;
}

.RequestCard_evaluateButton__uERrr {
    margin: 5px;

    background-color: var(--color-darkblue);
    border-radius: 5px;
    border:2px solid var(--color-white);
    padding-top: 5px;
    
    color: var(--color-white);
    font-family: Poppins;
    padding-bottom: 5px;
    padding-left: 50px;
    padding-right: 50px;
}

.RequestCard_rating__j-aZl {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: small;
}



@media only screen and (max-width: 1000px) {
 .RequestCard_cardPreview__1P9yW{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
     -webkit-justify-content: center;
             justify-content: center;
  }

  .RequestCard_profileColumn__1KopE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    font-size: small;
    min-width: 25%;
    margin: 5px;
}
  

}


.StudentRequests_cardHolder__2ICrQ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
}
.Students_rootContainer__2tk30 {
    margin: 50px;
    margin-left: 10vw;
    margin-right: 10vw;
}

.Students_buttonContainer__5fYTo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: start;
            justify-content: start;
    margin-bottom: 25px;

}

.Students_activeCourseButton__3Rn10 {
    font-family: Poppins;
    color: var(--color-darkblue);
    padding: 5px;
    margin-right: 5vw;
    font-weight: 500;
    border-radius: 5px;
    border:2px solid var(--color-pink);
    background-color: var(--color-pink);
}

.Students_normalCourseButton__2YNPw {
    background-color: var(--color-white);
    font-family: Poppins;
    color: var(--color-darkblue);
    margin-right: 5vw;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
    border:2px solid var(--color-pink);
    

}
.TutorLanding_TutorLanding__4Mnw9 {
  background-color: #2b324a;
  color: white;
  padding: 0;
  display: -webkit-flex;
  display: flex;

  -webkit-align-items: center;

          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
  box-sizing: border-box;
  max-width: 100vw;
}

.TutorLanding_firstPageDiv__1nhCX {
  position: relative;
  display: -webkit-flex;
  display: flex;
  max-width: 1500px;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background-color: #2b324a;
  min-height: 100vh;
}

.TutorLanding_element1__2gbr2 {
  position: absolute;
  top: 100px;
  width: 120px;
  left: 605px;
}
.TutorLanding_element12__3b3ga {
  position: absolute;
  bottom: 90px;
  width: 120px;
  z-index:1;
  right: 0px;
}
.TutorLanding_plus1__2Qy_N {
  position: absolute;
  top: 90px;
  width: 60px;
  left: 85px;
}

.TutorLanding_plus2__3Bvlx {
  position: absolute;
  bottom: 200px;
  width: 60px;
  right: 250px;
}


.TutorLanding_circle1__18Q7Y {
  position: absolute;
  top: 20px;
  width: 30px;
  right: 305px;
  z-index: 0;
}
.TutorLanding_circle2__2mxo6 {
  position: absolute;
  top: 300px;
  z-index: 01;
  width: 30px;
  left: 300px;
}
.TutorLanding_lowerImg__31sx5 {
  position: absolute;
  bottom: 12vh;
  width: 60px;
  right: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: start;
          justify-content: start;
}
  
 

.TutorLanding_dummyTeacher__3Ho_Y img {
  position:relative;
  margin-right: 5vh;
  margin-bottom: -11vh;
  width: 500px;
}
.TutorLanding_element4__bKc0Z {
  height: 50px;
  margin-left: -20px;
}
.TutorLanding_element5__2tMdR {
  
  height: 50px;
}

.TutorLanding_rightFirst__1APkd {

  padding: 20px 0px;
  max-width: 350px;
 
}

.TutorLanding_firstuppercaseLines__2-NY9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: 40vh;
  font-size: medium;
  text-transform: uppercase;
}
.TutorLanding_firstuppercaseLines__2-NY9 p {
  text-transform: uppercase;
  line-height: 20px;
}




.TutorLanding_uppercaseLines__GezMN {
  margin-top: 10px;
  font-size: medium;
  text-transform: uppercase;
  font-weight: 800;
  
}
.TutorLanding_uppercaseLines__GezMN p {
  text-transform: uppercase;
  line-height: 15px;
}

.TutorLanding_bluish__2yoH1 {
  color: #50d0db;
}
.TutorLanding_pinkfont__3UEK7 {
  color: #f085b0;
}

.TutorLanding_catchyheading__2aLqy {
  border-bottom: 1px white solid;
}

.TutorLanding_pinkButton__ugpcz {
  padding: 10px 45px;
  background-color: #f085b0;
  color: white;
  font-weight: 700;
  font-size: large;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
}

.TutorLanding_secondPageDiv__3DYDV {
  min-height: 100vh;
  background-color: #2b324a;
  display: -webkit-flex;
  display: flex;
 
  -webkit-flex-direction: column;
 
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  
}





.TutorLanding_leftSecond__2m2uN img,
.TutorLanding_rightSecond__2cqrK img {
  height: 200px;
}

.TutorLanding_bottomDiv__3sGoz {
  margin: 40px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.TutorLanding_bottomDiv__3sGoz button {
  margin-top: 20px;
  margin-bottom: 10px;
}

.TutorLanding_headerpagethree__33Gz- {
  text-align: center;
  /* font-size: 50px; */
}

.TutorLanding_thirdPagediv__219vP {
  margin: auto 0;
  /* height: 100%; */
  background-color: #2b324a;
  padding-bottom: 50px;
}

.TutorLanding_cardsDetails__1pEG8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.TutorLanding_card__1T8HV {
  -webkit-flex-basis: 30%;
          flex-basis: 30%;
  box-sizing: border-box;
  text-align: center;
  padding: 30px 20px;
}
.TutorLanding_inDepthImg__1vc4s {
  
  height: 100px;
  
}

.TutorLanding_thirdDescription__2COMk {
  
  font-size: large;

}
.TutorLanding_signupbuttondiv__3M74u {
  padding: 40px 0px;
  display: -webkit-flex;
  display: flex;
  margin-top: -30px;
  -webkit-align-items: center;
          align-items: center;
  
  -webkit-justify-content: center;
  
          justify-content: center;
}
.TutorLanding_rightFirstpara__1668j {

  text-transform: capitalize !important;}


@media only screen and (min-width: 1000px){


.TutorLanding_displayrow__AlrXs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-;
          justify-content: space-;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.TutorLanding_leftSecond__2m2uN,
  .TutorLanding_rightSecond__2cqrK {
    position: relative;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
  
    
  
  }





}


@media only screen and (max-width: 1000px) {
  .TutorLanding_firstPageDiv__1nhCX {
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
   
   -webkit-align-items: center;
   
           align-items: center;
    grid-gap: 0;
    gap: 0;
  }
  .TutorLanding_dummyTeacher__3Ho_Y{
    -webkit-align-items: center;
            align-items: center;
  }
  .TutorLanding_dummyTeacher__3Ho_Y img {
    margin-right: 0;
    height: 30vh;
    width: 30vh;

    margin-top: -15vh;
    margin-bottom: 0vh;
    content: url(/static/media/teacherShape.53f23eef.png);
  }
  .TutorLanding_circle1__18Q7Y {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 105px;
  }
  .TutorLanding_circle2__2mxo6 {
    position: absolute;
    top: 300px;
    
    width: 20px;
    right: 80px;
  }
  .TutorLanding_plus1__2Qy_N {
    position: absolute;
    top: 300px;
    width: 60px;
    left: 35px;
  }

  .TutorLanding_lowerImg__31sx5 {

    display: none;
  }

  .TutorLanding_plus2__3Bvlx {
    display: none;
  }
  .TutorLanding_element1__2gbr2 {
    position: absolute;
    top: 22px;
    width: 70px;
    left: 15px;
  }

  .TutorLanding_element12__3b3ga {
   display: none;
  }
  .TutorLanding_headerPageOne__29eUU {
    font-size: 15px;
    line-height: 30px;
  }
  .TutorLanding_rightFirst__1APkd {
    margin-top: -20vh;
    padding: 0;
    text-align: center;
   
  }
  .TutorLanding_rightFirstpara__1668j {
    margin-top: 5px;
    text-transform: capitalize;
    font-size: medium;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 1px;
  }
  .TutorLanding_pinkButton__ugpcz {
    margin-top: 20px;
  }

  .TutorLanding_displayrow__AlrXs {
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    
    
  }

  .TutorLanding_leftSecond__2m2uN,
  .TutorLanding_rightSecond__2cqrK {
    position: relative;
    text-align: center;
  
    
  
  }

  .TutorLanding_rightSecond__2cqrK {
    margin-top: 10px;
  }


  .TutorLanding_cardsDetails__1pEG8 {

    -webkit-flex-direction: column;

            flex-direction: column;

  }

  .TutorLanding_card__1T8HV {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    /* flex-grow: 1;
     */
  }





  




  .TutorLanding_leftSecond__2m2uN img,
  .TutorLanding_rightSecond__2cqrK img {
  
    height: 15vh;
  }

 

  .TutorLanding_inDepthImg__1vc4s {
    
    height: 100px;
  }

  .TutorLanding_thirdDescription__2COMk{
    
    font-size: large;
  }



  .TutorLanding_rightSecond__2cqrK img {
    padding-top: 20px;
  }

  .TutorLanding_headerpagethree__33Gz- {
    text-align: center;
    font-size: large;
  }

  .TutorLanding_secondPageDiv__3DYDV {
    margin-top: -200px;
    height: 100vh;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
  .TutorLanding_secondPageDiv__3DYDV .TutorLanding_pinkButton__ugpcz {
    padding: 10px;
  }

  .TutorLanding_bottomDiv__3sGoz {
    font-size: x-large;
  }

  .TutorLanding_boldText__3yubo {
    font-size: large;
  }
}

.CourseForm_CourseForm__1ZLlE {
  margin: 50px 30px;
}

.CourseForm_header__2oR2z {
  margin-bottom: 20px;
}
.CourseForm_formnav__2XcIc {
  list-style-type: none;
  margin-left: 0 !important;
  margin-bottom: 20px;
}
.CourseForm_formnav__2XcIc li {
  display: inline;
  margin-right: 50px;
}

.CourseForm_textfield__1LhXh {
  width: 100%;
  border-radius: 5px;
}
.CourseForm_unactiveli__2iwbD {
  padding: 2px;
}
.CourseForm_active__um5rh {
  border-bottom: 4px solid #64d8e0;
  padding: 2px;
}

.CourseForm_submitbutton__3ORBA {
  background-color: #64d8e0;
  border: none;
  padding: 10px 20px;
  font-size: 22px;
  border-radius: 8px;
  color: black;
  text-transform: uppercase;
}
.CourseForm_SetupForm__3GoQU {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 50px;
  gap: 50px;
}
.CourseForm_orgSetup__2iSOR {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 50px;
  gap: 50px;
}
.CourseForm_priceSetup__2LgRp {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  grid-gap: 50px;
  gap: 50px;
}

.CourseForm_leftSetup__1c2Tt {
  -webkit-flex-basis: 800px;
          flex-basis: 800px;
}

.CourseForm_rightSetup__3FZAG {
  -webkit-flex-basis: 350px;
          flex-basis: 350px;
}

.CourseForm_buttondiv__2KB8Y {
  margin: 10px 50px;
}

.CourseForm_textfieldcourseDetails__3LB0m {
  height: 40vh;
  width: 500px;
}

.CourseForm_courseHighlightsContent__1sA_a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
}

.CourseForm_courseHighlightsContent__1sA_a div {
  -webkit-flex-basis: 45%;
          flex-basis: 45%;
}

.CourseForm_paracourseHL___7QzH {
  margin: 10px 0px;
  font-size: 17px;
  line-height: 26px;
}

.CourseForm_paralinescourseHL__K3SGK {
  font-size: 17px;
  line-height: 26px;
}

input[type="file"]::file-selector-button {
  border: 2px solid #f085b0;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #f085b0;
  transition: 1s;
  font-size: 22px;
}

input[type="file"]::file-selector-button:hover {
  border: 2px solid #cc5577;
}
.CourseForm_fileInput__2d98y {
  border: 1px solid black;
  padding: 5px 10px;
  width: 90%;
  margin: 5px 0px;
  border-radius: 5px;
}
.CourseForm_addInstructor__1aE3B{
  margin-bottom: 50px;
}
.CourseForm_addInstructorUpper__1qTu0 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 40px;
  gap: 40px;
}

.CourseForm_addInstructorUpper__1qTu0 div {
  -webkit-flex-basis: auto;
          flex-basis: auto;
}

progress {
  display: block;
  width: 90%;
}


.CourseForm_addTopic__Ja6df,.CourseForm_faq__1kUOy{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.CourseForm_addTopicForm__6x36l, .CourseForm_addedTopic__3zV4D,.CourseForm_faqForm__2qqc7,.CourseForm_addedFAQs__1FSnk{
  -webkit-flex-basis: 47%;
          flex-basis: 47%;
}

.CourseForm_addedTopicList__3-h4v, .CourseForm_listOfFAQs__1lxnf{
  padding: 10px 0;
  margin-top: 10px;
  background-color: white;
  min-height: 34vh;
  max-height: 35vh;
  overflow-y: scroll;
  border: 1px solid gainsboro;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.CourseForm_highLightsList__1CokE{
  padding: 10px 0;
  margin: 10px 0;
  background-color: white;
  min-height: 14vh;
  max-height: 16vh;
  overflow-y: scroll;
  border: 1px solid gainsboro;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}




@media only screen and (max-width: 600px) {
  .CourseForm_unactiveli__2iwbD {
  display: none !important;
  }

  .CourseForm_active__um5rh{
    text-align: center;
  }

  .CourseForm_addInstructorUpper__1qTu0 div{
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }
  .CourseForm_courseHighlightsContent__1sA_a div {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 100%;
            flex-basis: 100%
  }
  .CourseForm_buttondiv__2KB8Y {
    margin-top: 10px;
    padding-bottom: 50px;
    margin-right: 0;
    margin-left: 0;
  }
  .CourseForm_submitbutton__3ORBA{
    font-size: 20px;
  }

  .CourseForm_leftSetup__1c2Tt {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 100%;
            flex-basis: 100%
  }
  
  .CourseForm_rightSetup__3FZAG {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 100%;
            flex-basis: 100%
  }
  .CourseForm_SetupForm__3GoQU{
    grid-gap:10px;
    gap:10px;
  }

  .CourseForm_addTopicForm__6x36l, .CourseForm_addedTopic__3zV4D,.CourseForm_faqForm__2qqc7,.CourseForm_addedFAQs__1FSnk{
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;

  }

  /* .addInstructorUpper {
  box-sizing: border-box;

    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  } */



}
.CoursePage_CoursePage__1xrPF {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 10px 60px;
}
.CoursePage_createCourse__3y3-Z{
    padding: 20px 40px;
}

.CoursePage_createCourseButton__2ggEF{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
}

.CoursePage_uncompletedCourses__XNi2X{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}

.CoursePage_uncompletedCoursesList__35Kfd{
    background-color: #2b324a;
    padding: 30px 10px;
    color: white;
    font-size: 22px;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;

}

.CoursePage_unActiveCourses__2K6xx, .CoursePage_activeCourses__P3Jet{
    margin: 30px 0;

}

@media screen and (max-width: 720px) {
    .CoursePage_CoursePage__1xrPF{
        padding: 20px 0;
    }
    .CoursePage_createCourse__3y3-Z{
        padding: 20px 0;
    }

    .CoursePage_uncompletedCourses__XNi2X{
        -webkit-justify-content: center;
                justify-content: center;
        
    }
    .CoursePage_uncompletedCoursesList__35Kfd{
        min-width: 90vw;
    }
}
