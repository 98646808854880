@import url('https://fonts.googleapis.com/css?family=Roboto');

.navbar{
    width: 100%;
    position: static;
    padding-top: 1%;
    height: 70px;
    background: hsl(244, 41%, 7%)
}
.rectangle{
    background: cornsilk;
    /* position: absolute; */
    width: 50px;
    height: 20px;
}
.items{
    /* margin-left: 50%; */
    font-family: 'Roboto';
    text-align: center;
    font-size: 20px;
}
.items span{
    z-index: 1;
}
.items span{
    margin-left: 5%;
}